export const ACTION_TYPES = {
  GET_LESSON_DETAIL: "GET_LESSON_DETAIL",
  GET_LESSON_DETAIL_SUCCESS: "GET_LESSON_DETAIL_SUCCESS",
  GET_LESSON_DETAIL_FAILED: "GET_LESSON_DETAIL_FAILED",
  GET_EXAM_DETAIL: "GET_EXAM_DETAIL",
  GET_EXAM_DETAIL_SUCCESS: "GET_EXAM_DETAIL_SUCCESS",
  GET_EXAM_DETAIL_FAILED: "GET_EXAM_DETAIL_FAILED",
  GET_PRACTICE_DETAIL: "GET_PRACTICE_DETAIL",
  GET_PRACTICE_DETAIL_SUCCESS: "GET_PRACTICE_DETAIL_SUCCESS",
  GET_PRACTICE_DETAIL_FAILED: "GET_PRACTICE_DETAIL_FAILED",
  POST_QUIZ_START_CORE: "POST_QUIZ_START_CORE",
  POST_QUIZ_START_CORE_SUCCESS: "POST_QUIZ_START_CORE_SUCCESS",
  POST_QUIZ_START_CORE_FAILED: "POST_QUIZ_START_CORE_FAILED",
  PUT_QUIZ_START: "PUT_QUIZ_START",
  PUT_QUIZ_START_SUCCESS: "PUT_QUIZ_START_SUCCESS",
  PUT_QUIZ_START_FAILED: "PUT_QUIZ_START_FAILED",
  PATCH_QUIZ_START: "PATCH_QUIZ_START",
  PATCH_QUIZ_START_SUCCESS: "PATCH_QUIZ_START_SUCCESS",
  PATCH_QUIZ_START_FAILED: "PATCH_QUIZ_START_FAILED",
  QUIZ_SUBMIT: "QUIZ_SUBMIT",
  QUIZ_SUBMIT_SUCCESS: "QUIZ_SUBMIT_SUCCESS",
  QUIZ_SUBMIT_FAILED: "QUIZ_SUBMIT_FAILED",
  GET_QUIZ_SOLUTION: "GET_QUIZ_SOLUTION",
  GET_QUIZ_SOLUTION_SUCCESS: "GET_QUIZ_SOLUTION_SUCCESS",
  GET_QUIZ_SOLUTION_FAILED: "GET_QUIZ_SOLUTION_FAILED",
  GET_QUIZ_ANALYSIS: "GET_QUIZ_ANALYSIS",
  GET_QUIZ_ANALYSIS_SUCCESS: "GET_QUIZ_ANALYSIS_SUCCESS",
  GET_QUIZ_ANALYSIS_FAILED: "GET_QUIZ_ANALYSIS_FAILED",
  CLEAN_QUIZ_INFO: "CLEAN_QUIZ_INFO",
  SAVE_ANSWER_TOTAL: "SAVE_ANSWER_TOTAL",
  CLEAN_NOTIFY: "CLEAN_NOTIFY",
  SAVE_QUIZ_START_TIME: "SAVE_QUIZ_START_TIME",
  CLEAN_CONGRATULATION: "CLEAN_CONGRATULATION",
  UPDATE_QUIZ_INFO_SOLUTION: "UPDATE_QUIZ_INFO_SOLUTION",
  REPORT_QUIZ: "REPORT_QUIZ",
  REPORT_QUIZ_SUCCESS: "REPORT_QUIZ_SUCCESS",
  REPORT_QUIZ_FAILED: "REPORT_QUIZ_FAILED",
  SAVE_QUIZ_QUESTION_GROUP_TAB: "SAVE_QUIZ_QUESTION_GROUP_TAB",
  GET_DETAIL_CARD: "GET_DETAIL_CARD",
  GET_DETAIL_CARD_SUCCESS: Symbol("GET_DETAIL_CARD_SUCCESS"),
  GET_DETAIL_CARD_FAILED: Symbol("GET_DETAIL_CARD_FAILED"),
  SET_IS_REDO_OR_CONTINUE: Symbol("SET_IS_REDO_OR_CONTINUE"),
};
