/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-this-alias */
import moment from "moment";
import { isNumber } from "lodash";
import { ACCESS_LEVEL } from "../../subjects/utils/contants";
import { isEmpty } from "../../../utils/constants/constants";
import { LIST_CLASSES } from "../../Default/layout/constants";
import { ROUTES } from "../../../utils/configs/router";

export const SUBSCRIBE_TOPIC_SCOPE_ASK = "ask";
export const ACCEPT_TYPE_IMAGE = ["jpg", "jpeg", "png", "raw"];
export const MAX_FILE_SIZE_IMAGE = 1048576; // bytes =  1MB

export const LOCATION_PERMISSION_UNDEFINE = -1;
export const LOCATION_PERMISSION_DENIED = 0;
export const LOCATION_PERMISSION_ACCEPTED = 1;

export const getFormData = (object: { [s: string]: unknown } | ArrayLike<unknown>) =>
  Object.entries(object).reduce((fd, [key, val]: any) => {
    if (Array.isArray(val)) {
      val.forEach((v) => fd.append(key, v));
    } else {
      fd.append(key, val);
    }
    return fd;
  }, new FormData());

export const removeAccents = (str: string) => {
  return str
    ?.normalize("NFD")
    ?.replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
};

export const stringToSlug = (str) => {
  // Chuyển hết sang chữ thường
  let newStr = str.toLowerCase();
  // xóa dấu
  newStr = newStr
    .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
    .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
  // Thay ký tự đĐ
  newStr = newStr.replace(/[đĐ]/g, "d");
  // Xóa ký tự đặc biệt
  newStr = newStr.replace(/([^0-9a-z-\s])/g, "");
  // Xóa khoảng trắng thay bằng ký tự -
  newStr = newStr.replace(/(\s+)/g, "-");
  // Xóa ký tự - liên tiếp
  newStr = newStr.replace(/-+/g, "-");
  // xóa phần dư - ở đầu & cuối
  newStr = newStr.replace(/^-+|-+$/g, "");
  return newStr;
};

let lastClick = 0;
export const debounceOnClick = (onClick: Function) => {
  if (lastClick + 500 > moment.now()) {
    lastClick = 0;
  } else {
    lastClick = moment.now();
    onClick();
  }
};

export const abbreviateNumberFormatter = (num, digits = 1) => {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  // eslint-disable-next-line no-plusplus
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
};

export const removeNullAttributeFromObject = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([_, v]) => !isEmpty(v)));

export const QUIZ_LEVEL_OBJECT = {
  NORMAL: 0,
  PREMIUM: 1,
  PDF: 2,
  DOC: 3
};

export const QUIZ_LEVEL = {
  NORMAL: {
    level: 0,
    title: "quiz.level.normal"
  },
  PREMIUM: {
    level: 2,
    title: "quiz.level.pdf"
  }
};

export const ERROR_CODE_NEED_LOGIN = "EFOBID";
export const ERROR_CODE_ONLY_ONE = "ENAAUC";
export const ERROR_CODE_END_OF_USE = "ENACEU";
export const ERROR_CODE_HAVE_NO_GIFTCODE = "ENFGIC";

export const LIST_PATH_NAME_NON_BLOCK = [
  "/hoi-dap?tab=thao-luan",
  "/[grade]/thi-online?tab=phong-thi",
  "/[grade]/[subjectsId]",
  "/[grade]/thi-online?tab=xep-hang",
  "/",
  "/hoi-dap/[postId]",
  "/hoi-dap?tab=xep-hang",
  "/[grade]/thi-online?tab=lich-su",
  "/[grade]/thi-online?tab=huy-hieu",
  "/hoi-dap?tab=huong-dan",
  "/[grade]/[subjectsId]/de-thi/[topicId]",
  "/[grade]/[subjectsId]/bai-tap/[topicId]",
  "/[grade]/[subjectsId]/ly-thuyet/[topicId]",
  "/[grade]/thi-online/phong-thi/[roomHid]",
  "/tai-khoan/[memberHid]",
  "/tin-tuc",
  "/tin-tuc/[newsId]",
  "/tim-kiem"
];

export const getDuplicateItemInArray = (array) => {
  return array.filter((element, index) => array.indexOf(element) !== index);
};

export const ERROR_CODE_NEED_BUY_VIP = "ENOVIP";

export const checkSlugHighLightSubject = (subjectId, slug) => {
  if (subjectId?.includes("mon")) {
    return subjectId?.includes(
      slug.replace("tieng", "mon") // do slug learningProgram server trả về lúc là tieng-anh, lúc là mon-anh
    );
  }
  return subjectId?.includes(slug);
};

export const LIST_DISABLED_BREADCRUMB = ["de-thi", "bai-tap", "ly-thuyet"];

export const REPORT_QUIZ = "REPORT_QUIZ";

export const quizAnswerType = {
  NONE: -1,
  ONE_ANSWER: 0,
  MANY_ANSWER: 1,
  PAIRING: 2,
  CONCAT_WORD: 3,
  CONCAT_NUMBER: 4,
  CONCAT_LETTER_VI: 5,
  CONCAT_LETTER_EN: 6,
  CONCAT_LETTER_MIX: 7,
  SORT_POSITION: 8
};
export const TYPE_EXAM = "exam";

// If immediate is passed as an argument to the function, the function triggers immediately and then waits for the interval before being called again.
export function debounceButton(func, wait = 300, immediate = true) {
  let timeout;
  function executedFunction() {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  }
  return executedFunction();
}

export const fixedNumber = (num: number) => {
  if (typeof num !== "number") return null;
  if (num <= 1) {
    return num?.toFixed(2);
  }
  if (num > 1 && num <= 99) {
    return num?.toFixed(1);
  }
  return num?.toFixed(0);
};

export const DEBOUNCE_TIMES_API = 300; // ms
export const QUIZ_TYPE_EXAM = "exam";
export const QUIZ_TYPE_PRACTICE = "practice";
export const QUIZ_PAGE_QUERY_DOING = "lam-bai";
export const QUIZ_PAGE_QUERY_RESULT = "ket-qua";

export const LIST_ROUTER_DIRECT_APP = ["/", `/[grade]/thi-online?tab=phong-thi`, "/thong-ke", "/hoi-dap?tab=thao-luan"];
export const isShowLock = (accessLevel, dataUser) => {
  if (dataUser?.member?.pub_id?.length > 0) {
    if (!dataUser?.member?.vip_from || dataUser?.member?.vip_to < moment().unix()) {
      return ACCESS_LEVEL.LOGIN === accessLevel || ACCESS_LEVEL.NOT_LOGIN === accessLevel;
    }
    return (
      ACCESS_LEVEL.PREMIUM.includes(accessLevel) ||
      ACCESS_LEVEL.LOGIN === accessLevel ||
      ACCESS_LEVEL.NOT_LOGIN === accessLevel
    );
  }
  return ACCESS_LEVEL.NOT_LOGIN === accessLevel;
};

export const additionalThresholdScroll = 500;

export const CARD_TYPE = {
  KANJI: "kanji",
  VOCABULARY: "vocabulary",
  GRAMMAR: "grammar"
};
export const LIST_ERROR_CODE_GIVE_DIAMOND = ["ETMTIM", "EOLGDM", "EOLGDO", "ELPGDI", "ENAGFY"];
export const BLOCK_SUBJECTS = ["A", "A1", "B", "C", "D", "H", "V"];

export const ADS_PAGE_POSITION_HOME = "home";
export const ADS_PAGE_POSITION_STATISTIC = "statistic";
export const ADS_PAGE_POSITION_QUIZ = "quiz";
export const ADS_PAGE_POSITION_ARENA = "arena";

export const POSITION_ADS_IN_HOUSE = {
  promotion: 1,
  recommend: 2,
  bannerAds: 3,
  homeDialog: 4,
  statistic: 6,
  quiz: 7,
  resultArena: 8
};

export const ADS_IN_HOUSE_ACTION_CLICK = "click";
export const BREAK_SCORE_TO_GET_SUBJECT_PARAM = 7;
export const BLOCK_SUBJECT_OBJECT = {
  A: ["toan-hoc", "vat-ly", "hoa-hoc"],
  A1: ["toan-hoc", "hoa-hoc", "tieng-anh"],
  B: ["toan-hoc", "hoa-hoc", "sinh-hoc"],
  C: ["ngu-van", "lich-su", "dia-ly"],
  D: ["ngu-van", "toan-hoc", "tieng-anh"],
  H: ["ngu-van", "my-thuat"],
  V: ["toan-hoc", "vat-ly", "my-thuat"]
};

export const CONFIG = {
  GAME_NAME: "game_name",
  GAME_LINK: "game_link"
};

export const removePropertiesFromObject = (obj, ...properties) => {
  const clonedObj = { ...obj };
  properties.forEach((key) => delete clonedObj[key]);
  return clonedObj;
};

export const CONTENT_TYPE = {
  HTML: "html",
  IMAGE: "image",
  AUDIO: "audio"
};

export const getQueryFilterListPost = (store) => {
  const { listPostType, listGradeChoose, listSubjectChoose, timeIdChoose, isCleanFilter, isFilterListPost } =
    store?.getState().questionAndAnswer || {};
  const grade = store?.getState().commonNew.grade;
  const gradeIdParams = isCleanFilter
    ? undefined
    : listGradeChoose?.length > 0 && listGradeChoose.every((e) => isNumber(e))
    ? listGradeChoose.join(",")
    : LIST_CLASSES?.find((e) => e?.slug === grade)?.value;
  return {
    gr: gradeIdParams,
    fty: listPostType?.join(","),
    fti: timeIdChoose,
    gst: listSubjectChoose?.map((item) => `${item?.parentId},${item?.subjectId}`),
    is_filter: isFilterListPost ? 1 : 0,
    all: isCleanFilter
  };
};

export const SURVEY_SCREEN = {
  [ROUTES.home.path]: "/home",
  [ROUTES.subjects.path]: "/exam-selection",
  [ROUTES.topics.lythuyet.path]: "/lesson",
  [ROUTES.topics.dethi.path]: "/exam-question",
  [ROUTES.topics.baitap.path]: "/exam-question",
  [ROUTES.thionline.path]: "/arena-overview",
  [ROUTES.thionline.phongdau.detailRoom.path]: "/detail-room",
  [ROUTES.thionline.phongdau.ketqua.path]: "/result_member",
  [ROUTES.hoidap.index]: "/forum-overview",
  [ROUTES.hoidap.detail.path]: "/detail-post",
  [ROUTES.giftItem.path]: "/gift-store",
  [ROUTES.kimcuong.path]: "/quest-list",
  [ROUTES.thongke.path]: "/statistics"
};

export const getSurveyScreen = (path, router) => {
  if ([ROUTES.topics.dethi.path, ROUTES.topics.baitap.path].includes(path) && router?.query?.p === "ket-qua") {
    return "/question-result";
  }
  if (path in SURVEY_SCREEN) {
    return SURVEY_SCREEN[path];
  }
  return router.pathname;
};

export const SURVEY_DIALOG_TYPE = {
  INSIDE: "inside",
  AFTER: "after"
};

export const SURVEY_CONTENT_TYPE = { ONCE_CHOICE: "one_choice", MULTIPLE_CHOICE: "multi_choice", TEXT: "text" };

export const getNativeOnScreen = (survey, path, router) => {
  const currentScreen = getSurveyScreen(path, router);
  return survey?.items?.filter((ele) => !!ele?.location_survey?.native && ele?.screen === currentScreen);
  // ?.reduce((a, v) => ({ ...a, [v?.location_survey?.native as string]: v }), {});
};

export const NATIVE_SURVEY_TYPE = {
  TOP: "top",
  CENTER: "center",
  BOTTOM: "bottom"
};
export const ORDER_SURVEY_NATIVE = {
  [NATIVE_SURVEY_TYPE.TOP]: -100,
  [NATIVE_SURVEY_TYPE.BOTTOM]: 100
};

export const SHOW_MAXIMUM_EXAMS = 3;

export const TIMESTAMP_OF_DAY = 864e5;

export const GET_DAILY_REPORT_DATE_LOCAL_STORAGE_KEY = "GET_DAILY_REPORT_DATE";

export const SHOW_MAXIMUM_EXERCISE_DONE_IN_DAILY_REPORT_DIALOG = 10;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneRegex = /^(([+]?84)|0[3|5|7|8|9])+([0-9]{8})$/;

export const MAXIMUM_TEACHER_NAME_LENGTH = 64;
export const MAXIMUM_TEACHER_SCHOOL_LENGTH = 250;
export const MAXIMUM_TEACHER_EXPERIENCE_LENGTH = 64;
export const MAXIMUM_TEACHER_CERTIFICATE_LENGTH = 250;
export const MAXIMUM_TEACHER_ACHIEVED_ACHIEVEMENT_LENGTH = 512;

export const LANG_VOICE_DEFAULT = "ja-JP";
export const TOPIC_OPENING_VIEW_TYPE_TAB = {
  LESSONS: "ly-thuyet",
  PRACTICE: "bai-tap",
  EXAMS: "de-thi",
  CARD: "the"
};
export const TOPIC_TYPE = {
  LESSON: "lesson",
  CARD: "card",
  PRACTICE: "practice",
  EXAM: "exam"
};
export const ITEMS_PER_PAGE = 20;
export const NUM_PAGE_GET_DATA_CARD = 1;

export const BASE_LANGUAGES = ["en", "vi", "ja"];
export const DEBOUNCE_TIMES_COPY = 500;
