/* eslint-disable react/require-default-props */
import React from "react";

// eslint-disable-next-line no-unused-vars
type IconChatsCircleActive = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export default function IconChatsCircleActive({
  className,
  fill,
  width,
  height
}: IconChatsCircleActive) {
  return (
    <svg
      className={className}
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.8"
        d="M22.6667 11.9998C22.6667 17.1598 18.1867 21.3332 12.6667 21.3332L11.4267 22.8265L10.6934 23.7065C10.0667 24.4532 8.86668 24.2932 8.45335 23.3998L6.66669 19.4665C4.24002 17.7598 2.66669 15.0532 2.66669 11.9998C2.66669 6.83984 7.14669 2.6665 12.6667 2.6665C16.6934 2.6665 20.1734 4.89318 21.7334 8.09318C22.3334 9.27985 22.6667 10.5998 22.6667 11.9998Z"
        fill="white"
      />
      <path
        d="M29.3334 17.1466C29.3334 20.1999 27.76 22.9066 25.3334 24.6133L23.5467 28.5466C23.1334 29.4399 21.9333 29.6133 21.3067 28.8533L19.3334 26.4799C16.1067 26.4799 13.2267 25.0532 11.4267 22.8266L12.6667 21.3333C18.1867 21.3333 22.6667 17.1599 22.6667 11.9999C22.6667 10.5999 22.3334 9.27993 21.7334 8.09326C26.0934 9.09326 29.3334 12.7732 29.3334 17.1466Z"
        fill="white"
      />
      <path
        d="M16 13H9.33331C8.78665 13 8.33331 12.5467 8.33331 12C8.33331 11.4533 8.78665 11 9.33331 11H16C16.5466 11 17 11.4533 17 12C17 12.5467 16.5466 13 16 13Z"
        fill="white"
      />
    </svg>
  );
}
