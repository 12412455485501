/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put, takeEvery, takeLatest, debounce } from "redux-saga/effects";
import { toastSuccess } from "../../common/components/reactToastify";
import { ACTION_TYPES } from "./actionTypes";
import { quizServices } from "./services";

function* handleGetLessonDetail(action) {
  try {
    const response = yield call(quizServices.getLessonDetail, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_LESSON_DETAIL_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_LESSON_DETAIL_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetPracticeDetail(action) {
  try {
    const response = yield call(quizServices.getPracticeDetail, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_PRACTICE_DETAIL_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_PRACTICE_DETAIL_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetExamDetail(action) {
  try {
    const response = yield call(quizServices.getExamDetail, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_EXAM_DETAIL_SUCCESS,
        params: response?.data?.data
      });
      action.successCallback();
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_EXAM_DETAIL_FAILED,
      params: err?.data?.error
    });
  }
}
function* handlePostQuizStart(action) {
  try {
    const response = yield call(quizServices.quizzesStart, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.POST_QUIZ_START_CORE_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.POST_QUIZ_START_CORE_FAILED,
      params: err?.data?.error
    });
  }
}
function* handlePutQuizStart(action) {
  try {
    const response = yield call(quizServices.putQuizzesStart, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.PUT_QUIZ_START_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.PUT_QUIZ_START_FAILED,
      params: err?.data?.error
    });
  }
}
function* handlePatchQuizStart(action) {
  try {
    const response = yield call(quizServices.patchQuizzesStart, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.PATCH_QUIZ_START_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.PATCH_QUIZ_START_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleQuizSubmit(action) {
  try {
    const response = yield call(quizServices.quizzesSubmit, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.QUIZ_SUBMIT_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.QUIZ_SUBMIT_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetQuizSolution(action) {
  try {
    const response = yield call(quizServices.quizzesSolutions, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_QUIZ_SOLUTION_SUCCESS,
        params: { ...response?.data?.data, exam_topic_ids: action?.params?.exam_topic_ids }
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_QUIZ_SOLUTION_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetAnalysis(action) {
  try {
    const response = yield call(quizServices.getAnalysis, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_QUIZ_ANALYSIS_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_QUIZ_ANALYSIS_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleReportQuiz(action) {
  try {
    const response = yield call(quizServices.reportQuiz, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.REPORT_QUIZ_SUCCESS,
        params: response?.data?.data
      });
      toastSuccess(response?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.REPORT_QUIZ_FAILED,
      params: err?.data?.error
    });
  }
}
function* getDetailCard(action) {
  try {
    const response = yield call(quizServices.getCardDetail, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_DETAIL_CARD_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_DETAIL_CARD_FAILED,
      params: err?.data?.error
    });
  }
}
function* quizSagas() {
  yield takeEvery(ACTION_TYPES.GET_LESSON_DETAIL, handleGetLessonDetail);
  yield takeEvery(ACTION_TYPES.GET_EXAM_DETAIL, handleGetExamDetail);
  yield takeEvery(ACTION_TYPES.GET_PRACTICE_DETAIL, handleGetPracticeDetail);
  yield debounce(100, ACTION_TYPES.POST_QUIZ_START_CORE, handlePostQuizStart);
  yield takeEvery(ACTION_TYPES.PATCH_QUIZ_START, handlePatchQuizStart);
  yield takeEvery(ACTION_TYPES.PUT_QUIZ_START, handlePutQuizStart);
  yield takeLatest(ACTION_TYPES.QUIZ_SUBMIT, handleQuizSubmit);
  yield takeEvery(ACTION_TYPES.GET_QUIZ_SOLUTION, handleGetQuizSolution);
  yield takeEvery(ACTION_TYPES.GET_QUIZ_ANALYSIS, handleGetAnalysis);
  yield takeEvery(ACTION_TYPES.REPORT_QUIZ, handleReportQuiz);
  yield takeEvery(ACTION_TYPES.GET_DETAIL_CARD, getDetailCard);
}
export default quizSagas;
