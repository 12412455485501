import React from "react";

const Japanese = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1660_90682)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#F0F0F0"
        />
        <path
          d="M20.0003 28.6959C24.8028 28.6959 28.696 24.8028 28.696 20.0003C28.696 15.1978 24.8028 11.3047 20.0003 11.3047C15.1979 11.3047 11.3047 15.1978 11.3047 20.0003C11.3047 24.8028 15.1979 28.6959 20.0003 28.6959Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1660_90682">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Japanese;

export const JapaneseSmall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1679_107006)">
        <path
          d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z"
          fill="#F0F0F0"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M12 17.2179C14.8815 17.2179 17.2174 14.882 17.2174 12.0006C17.2174 9.1191 14.8815 6.7832 12 6.7832C9.11849 6.7832 6.78259 9.1191 6.78259 12.0006C6.78259 14.882 9.11849 17.2179 12 17.2179Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1679_107006">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
