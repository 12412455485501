/* eslint-disable no-param-reassign */
import Lottie from "lottie-react";
import * as React from "react";
import { PixelCrop } from "react-image-crop";
import IconChatsCircle from "../../../../public/assets/icons/IconChatsCircle";
import IconChatsCircleActive from "../../../../public/assets/icons/IconChatsCircleActive";
import IconExamsOnline from "../../../../public/assets/icons/IconExamsOnline";
import IconExamsOnlineActive from "../../../../public/assets/icons/IconExamsOnlineActive";
import IconHome from "../../../../public/assets/icons/IconHome";
import IconHomeActive from "../../../../public/assets/icons/IconHomeActive";
import IconN1 from "../../../../public/assets/icons/IconN1";
import IconN2 from "../../../../public/assets/icons/IconN2";
import IconN3 from "../../../../public/assets/icons/IconN3";
import IconN4 from "../../../../public/assets/icons/IconN4";
import IconN5 from "../../../../public/assets/icons/IconN5";
import IconStatistic from "../../../../public/assets/icons/IconSatistic";
import IconStatisticActive from "../../../../public/assets/icons/IconSatisticActive";
import DiamondIcon from "../../../../public/assets/jsons/DiamondIcon.json";
import English, { EnglishSmall } from "./assets/icons/English";
import Japanese, { JapaneseSmall } from "./assets/icons/Japanese";
import Vietnamese, { VietnameseSmall } from "./assets/icons/Vietnamese";
import { TListClasses, TListMenu } from "./type";

export const DRAWER_WIDTH = 300;
export const TITLE = "Jlpt Up";

export const LIST_MENU: TListMenu = [
  {
    key: "common.bottombar.home",
    link: "/",
    iconActive: <IconHomeActive />,
    icon: <IconHome />,
    iconMobile: <IconHome width="24" height="24" />,
    iconMobileActive: <IconHomeActive width="24" height="24" className="svgFillAll" fill="#FCFCFC" />
  },
  {
    key: "Question_and_Answer",
    link: "/hoi-dap?tab=thao-luan",
    iconActive: <IconChatsCircleActive />,
    icon: <IconChatsCircle />,
    iconMobile: <IconChatsCircle width="24" height="24" />,
    iconMobileActive: <IconChatsCircleActive width="24" height="24" className="svgFillAll" fill="#FCFCFC" />
  },
  {
    key: "common.bottombar.arena",
    link: "/lop/:grade/thi-online",
    iconActive: <IconExamsOnlineActive />,
    icon: <IconExamsOnline />,
    iconMobile: <IconExamsOnline width="24" height="24" />,
    iconMobileActive: <IconExamsOnlineActive width="24" height="24" className="svgFillAll" fill="#FCFCFC" />
  },

  {
    key: "common.bottombar.statistics",
    link: "/thong-ke",
    iconActive: <IconStatisticActive />,
    icon: <IconStatistic />,
    iconMobile: <IconStatistic width="24" height="24" />,
    iconMobileActive: <IconStatisticActive />
  },

  // {
  //   key: "common.bottombar.gift",
  //   link: "/qua-tang",
  //   iconActive: <IconGiftItemActive />,
  //   icon: <IconGiftItem />,
  //   iconMobile: <IconGiftItem width="24" height="24" />,
  //   iconMobileActive: (
  //     <IconGiftItemActive
  //       width="24"
  //       height="24"
  //       className="svgFillAll"
  //       fill="#FCFCFC"
  //     />
  //   )
  // }

  {
    key: "common.bottombar.diamond",
    link: "/kim-cuong",
    // id: "header_diamond",
    iconActive: <Lottie className="w-[24px] h-[24px]" animationData={DiamondIcon} loop />,
    icon: <Lottie className="w-[24px] h-[24px]" animationData={DiamondIcon} loop />,
    iconMobile: <Lottie className="w-[24px] h-[24px]" animationData={DiamondIcon} loop />,
    iconMobileActive: <Lottie className="w-[24px] h-[24px]" animationData={DiamondIcon} loop />
  }
];

export const LIST_CLASSES: TListClasses = [
  {
    shortName: "n1",
    name: "N1",
    value: 1,
    icon: <IconN1 />,
    slug: "cap-do-n1"
  },
  {
    shortName: "n2",
    name: "N2",
    value: 2,
    icon: <IconN2 />,
    slug: "cap-do-n2"
  },
  {
    shortName: "n3",
    name: "N3",
    value: 3,
    icon: <IconN3 />,
    slug: "cap-do-n3"
  },
  {
    shortName: "n4",
    name: "N4",
    value: 4,
    icon: <IconN4 />,
    slug: "cap-do-n4"
  },
  {
    shortName: "n5",
    name: "N5",
    value: 5,
    icon: <IconN5 />,
    slug: "cap-do-n5"
  }
];

export const USER_CLASS = "USER_CLASS";

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

  ctx.restore();
}

export const LIST_FORMAT_URL_IMAGE = ["jpg", "jpeg", "png"];
export const LIST_ERROR_CODE_INVALID_TOKEN = ["ETKINI", "ETKINO"];
export const PATH_NAME = {
  homePage: "/"
};

export const LIST_LANGUAGE = [
  {
    title: "vietnamese",
    value: "vi",
    icon: <Vietnamese />,
    iconSmall: <VietnameseSmall />
  },
  {
    title: "english",
    value: "en",
    icon: <English />,
    iconSmall: <EnglishSmall />
  },
  {
    title: "japanese",
    value: "ja",
    icon: <Japanese />,
    iconSmall: <JapaneseSmall />
  }
];
