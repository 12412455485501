import api from '../../../utils/helpers/api';

export const getListRoom = (params: { grade?: string }, data?: any) => {
  return api({
    method: 'get',
    url: `arena/${params.grade}/rooms`,
    params,
    data,
  });
};

export const getCreationInfo = (params: { grade?: string }, data?: any) => {
  return api({
    method: 'get',
    url: `arena/${params.grade}/rooms/creation-info`,
    params,
    data,
  });
};

export const createRoomArena = (data?: any) => {
  return api({
    method: 'post',
    url: `arena/rooms`,
    data,
  });
};
