import api from "../../../utils/helpers/api";

const getRoomByRoomHid = (params) => {
  return api({
    method: "get",
    url: `arena/rooms/${params.roomHid}`
  });
};
const getMember = (params) => {
  return api({
    method: "get",
    url: `arena/rooms/${params?.roomHid}/members`,
    params
  });
};
const leaveRoom = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.roomHid}/leave`,
    data
  });
};
const joinRoom = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.room_hid}/join`,
    data
  });
};
const addMember = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.room_hid}/add`,
    data
  });
};
const createRoom = (data) => {
  return api({
    method: "post",
    url: `arena/rooms`,
    data
  });
};
const getCreationInfo = (params) => {
  return api({
    method: "get",
    url: `arena/${params}/rooms/creation-info`,
    params
  });
};
const updateRoom = (data) => {
  return api({
    method: "patch",
    url: `arena/rooms/${data.room_hid}`,
    data
  });
};
const deleteRoom = (data) => {
  return api({
    method: "delete",
    url: `arena/rooms/${data.room_hid}`,
    data
  });
};
const deleteMember = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.room_hid}/remove`,
    data
  });
};
const getRanking = (params) => {
  return api({
    method: "get",
    url: `arena/${params.grade}/rankings`,
    params
  });
};
const getHistory = (params) => {
  return api({
    method: "get",
    url: `arena/${params.package_id}/history`,
    params
  });
};
const getQuizQuestions = (params) => {
  return api({
    method: "get",
    url: `arena/rooms/${params.roomHid}/questions`
  });
};
const postQuizStart = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.roomHid}/start`,
    data
  });
};
const submitQuiz = (data) => {
  return api({
    method: "post",
    url: `arena/rooms/${data.roomHid}/submit`,
    data
  });
};
const getQuizSolutions = (params) => {
  return api({
    method: "get",
    url: `arena/rooms/${params.room_hid}/solutions`,
    params
  });
};
const patchQuizStart = (data) => {
  return api({
    method: "patch",
    url: `arena/rooms/${data.roomHid}/start`,
    data
  });
};
const getAnalysis = (params) => {
  return api({
    method: "get",
    url: `arena/rooms/${params.roomHid}/analysis/${params.memberHid}`
  });
};
const getListSubject = (params) => {
  return api({
    method: "get",
    url: `grades/${params?.grade}/subjects`
  });
};
export const arenaRoomServices = {
  getRoomByRoomHid,
  getMember,
  leaveRoom,
  joinRoom,
  addMember,
  createRoom,
  getCreationInfo,
  updateRoom,
  deleteRoom,
  deleteMember,
  getRanking,
  getHistory,
  getQuizQuestions,
  postQuizStart,
  submitQuiz,
  getQuizSolutions,
  patchQuizStart,
  getAnalysis,
  getListSubject
};
