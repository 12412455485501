import React from "react";

const English = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1660_90661)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#F0F0F0"
        />
        <path
          d="M4.13289 7.82129C2.56187 9.86527 1.37727 12.2211 0.6875 14.7804H11.092L4.13289 7.82129Z"
          fill="#0052B4"
        />
        <path
          d="M39.3106 14.7813C38.6209 12.2221 37.4362 9.86625 35.8652 7.82227L28.9062 14.7813H39.3106Z"
          fill="#0052B4"
        />
        <path
          d="M0.6875 25.2168C1.37734 27.776 2.56195 30.1319 4.13289 32.1758L11.0918 25.2168H0.6875Z"
          fill="#0052B4"
        />
        <path
          d="M32.1739 4.13297C30.1299 2.56195 27.7741 1.37734 25.2148 0.6875V11.092L32.1739 4.13297Z"
          fill="#0052B4"
        />
        <path
          d="M7.82031 35.8632C9.8643 37.4342 12.2202 38.6188 14.7794 39.3087V28.9043L7.82031 35.8632Z"
          fill="#0052B4"
        />
        <path
          d="M14.7793 0.6875C12.2201 1.37734 9.86422 2.56195 7.82031 4.13289L14.7793 11.0919V0.6875Z"
          fill="#0052B4"
        />
        <path
          d="M25.2148 39.3087C27.7741 38.6188 30.1299 37.4342 32.1738 35.8633L25.2148 28.9043V39.3087Z"
          fill="#0052B4"
        />
        <path
          d="M28.9062 25.2168L35.8652 32.1758C37.4362 30.1319 38.6209 27.776 39.3106 25.2168H28.9062Z"
          fill="#0052B4"
        />
        <path
          d="M39.8307 17.3913H22.6088H22.6087V0.169297C21.7548 0.058125 20.8841 0 20 0C19.1157 0 18.2452 0.058125 17.3913 0.169297V17.3912V17.3913H0.169297C0.058125 18.2452 0 19.1159 0 20C0 20.8843 0.058125 21.7548 0.169297 22.6087H17.3912H17.3913V39.8307C18.2452 39.9419 19.1157 40 20 40C20.8841 40 21.7548 39.942 22.6087 39.8307V22.6088V22.6087H39.8307C39.9419 21.7548 40 20.8843 40 20C40 19.1159 39.9419 18.2452 39.8307 17.3913Z"
          fill="#D80027"
        />
        <path
          d="M25.2188 25.2188L34.1434 34.1435C34.5539 33.7332 34.9455 33.3042 35.3191 32.8595L27.6783 25.2188H25.2188V25.2188Z"
          fill="#D80027"
        />
        <path
          d="M14.7841 25.2188H14.784L5.85938 34.1434C6.26969 34.5538 6.69867 34.9454 7.14336 35.319L14.7841 27.678V25.2188Z"
          fill="#D80027"
        />
        <path
          d="M14.7839 14.7823V14.7822L5.85922 5.85742C5.44875 6.26773 5.05719 6.69672 4.68359 7.14141L12.3245 14.7823H14.7839V14.7823Z"
          fill="#D80027"
        />
        <path
          d="M25.2188 14.784L34.1435 5.85914C33.7332 5.44867 33.3042 5.05711 32.8595 4.68359L25.2188 12.3245V14.784Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_1660_90661">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default English;

export const EnglishSmall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1679_134011)">
        <path
          d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
          fill="#F0F0F0"
          stroke="white"
        />
        <path
          d="M2.48069 4.69287C1.53808 5.91926 0.827311 7.33278 0.413452 8.86835H6.65617L2.48069 4.69287Z"
          fill="#0052B4"
        />
        <path
          d="M23.587 8.8688C23.1731 7.33327 22.4623 5.91975 21.5198 4.69336L17.3444 8.8688H23.587Z"
          fill="#0052B4"
        />
        <path
          d="M0.413452 15.1304C0.827359 16.6659 1.53812 18.0794 2.48069 19.3058L6.65603 15.1304H0.413452Z"
          fill="#0052B4"
        />
        <path
          d="M19.3056 2.47988C18.0792 1.53727 16.6657 0.826504 15.1301 0.412598V6.65527L19.3056 2.47988Z"
          fill="#0052B4"
        />
        <path
          d="M4.69397 21.5176C5.92036 22.4602 7.33388 23.171 8.86941 23.5849V17.3423L4.69397 21.5176Z"
          fill="#0052B4"
        />
        <path
          d="M8.86936 0.412598C7.33383 0.826504 5.92031 1.53727 4.69397 2.47983L8.86936 6.65522V0.412598Z"
          fill="#0052B4"
        />
        <path
          d="M15.1301 23.5849C16.6657 23.171 18.0792 22.4602 19.3055 21.5177L15.1301 17.3423V23.5849Z"
          fill="#0052B4"
        />
        <path
          d="M17.3444 15.1304L21.5198 19.3058C22.4623 18.0795 23.1731 16.6659 23.587 15.1304H17.3444Z"
          fill="#0052B4"
        />
        <path
          d="M23.8984 10.4348H13.5653H13.5653V0.101578C13.0529 0.034875 12.5305 0 12 0C11.4694 0 10.9471 0.034875 10.4348 0.101578V10.4347V10.4347H0.101578C0.034875 10.9471 0 11.4695 0 12C0 12.5306 0.034875 13.0529 0.101578 13.5652H10.4347H10.4347V23.8984C10.9471 23.9651 11.4694 24 12 24C12.5305 24 13.0529 23.9652 13.5652 23.8984V13.5653V13.5653H23.8984C23.9651 13.0529 24 12.5306 24 12C24 11.4695 23.9651 10.9471 23.8984 10.4348Z"
          fill="#D80027"
        />
        <path
          d="M15.1304 15.1314L20.4852 20.4862C20.7315 20.24 20.9664 19.9826 21.1906 19.7158L16.6061 15.1313H15.1304V15.1314Z"
          fill="#D80027"
        />
        <path
          d="M8.86951 15.1313H8.86941L3.51465 20.4861C3.76084 20.7324 4.01823 20.9673 4.28504 21.1915L8.86951 16.6069V15.1313Z"
          fill="#D80027"
        />
        <path
          d="M8.86939 8.86911V8.86902L3.51458 3.51416C3.2683 3.76035 3.03336 4.01774 2.8092 4.28455L7.39372 8.86907H8.86939V8.86911Z"
          fill="#D80027"
        />
        <path
          d="M15.1304 8.87029L20.4852 3.51539C20.239 3.26911 19.9817 3.03417 19.7148 2.81006L15.1304 7.39457V8.87029Z"
          fill="#D80027"
        />
        <path
          d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z"
          stroke="white"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1679_134011">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
