/* eslint-disable */
import axios from "axios";
import Qs from "qs";
const request = axios.create();

const apiAdsInHouse = (options: any) => {
  options.headers = {
    ...options.headers,
    ["Client-Key"]: process.env.NEXT_PUBLIC_APP_CLIENT_SECRET_KEY_ADS_IN_HOUSE,
    "Accept-Language": "vi"
  };
  return request({
    baseURL: `${process.env.NEXT_PUBLIC_BASE_URL_API_ADS_IN_HOUSE}`,
    ...options,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: "comma" }),
    headers: {
      ...options.headers
    }
  });
};

export default apiAdsInHouse;
