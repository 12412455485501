import api from "../../../utils/helpers/api";

export const getLessonDetail = (params) => {
  const option = {
    method: "get",
    url: `topics/${params.topicId}/lesson`
  };
  return api(option);
};

export const getExamDetail = (params) => {
  const option = {
    method: "get",
    url: `topics/${params?.topicId}/exam`,
    params
  };
  return api(option);
};

export const getPracticeDetail = (params) => {
  const option = {
    method: "get",
    url: `topics/${params?.topicId}/practice`,
    params
  };
  return api(option);
};

export const quizzesStart = (data) => {
  const option = {
    method: "post",
    url: `quizzes/start`,
    data
  };
  return api(option);
};

export const putQuizzesStart = (data) => {
  const option = {
    method: "put",
    url: `quizzes/start`,
    data
  };
  return api(option);
};

export const patchQuizzesStart = (data) => {
  const option = {
    method: "patch",
    url: `quizzes/start`,
    data
  };
  return api(option);
};

export const quizzesSubmit = (data) => {
  const option = {
    method: "post",
    url: `quizzes/submit`,
    data
  };
  return api(option);
};

export const quizzesSolutions = (params) => {
  const option = {
    method: "get",
    url: `quizzes/solutions/${params.topic_slug}`,
    params
  };
  return api(option);
};

export const getAnalysis = (params) => {
  const option = {
    method: "get",
    url: `analysis/${params.topicId}`
  };
  return api(option);
};

export const reportQuiz = (data) => {
  const option = {
    method: "post",
    url: `report/quiz`,
    data
  };
  return api(option);
};
export const getCardDetail = (params) => {
  return api({
    method: "get",
    url: `topics/${params?.topic_id}/card`,
    params
  });
};
export const quizServices = {
  getLessonDetail,
  getExamDetail,
  getPracticeDetail,
  quizzesStart,
  putQuizzesStart,
  patchQuizzesStart,
  quizzesSubmit,
  quizzesSolutions,
  getAnalysis,
  reportQuiz,
  getCardDetail
};
