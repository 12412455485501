/* eslint-disable default-param-last */
import { HYDRATE } from "next-redux-wrapper";
import { TSearchState } from "../utils/types";
import ACTION_TYPES_SEARCH from "./actionTypes";

export const initialStateSearch: TSearchState = {
  topicBySearch: {
    items: [],
    exams: [],
    practices: [],
    subject_types: [],
    subjects: [],
    learning_programs: []
  },
  tab: "the",
  isLoading: false,
  notifyError: {
    isOpenDialog: false,
    errorCode: "",
    message: ""
  },
  openModalJoinQuiz: false
};

const searchReducer = (state = initialStateSearch, action) => {
  switch (action.type) {
    case ACTION_TYPES_SEARCH.GET_TOPIC_BY_SEARCH: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES_SEARCH.GET_TOPIC_BY_SEARCH_SUCCESS: {
      return {
        ...state,
        topicBySearch: action?.params,
        isLoading: false
      };
    }
    case ACTION_TYPES_SEARCH.GET_TOPIC_BY_SEARCH_FAILED: {
      return {
        ...state,
        topicBySearch: {},
        notifyError: {
          isOpenDialog: true,
          errorCode: action?.params?.code,
          message: action?.params?.message
        },
        isLoading: false
      };
    }
    case ACTION_TYPES_SEARCH.CHOOSE_TAB: {
      return {
        ...state,
        tab: action.params
      };
    }
    case ACTION_TYPES_SEARCH.OPEN_MODAL_JOIN_QUIZ: {
      return {
        ...state,
        openModalJoinQuiz: action.params
      };
    }
    case HYDRATE: {
      return {
        ...state,
        topicBySearch: action?.payload?.search?.topicBySearch || null
      };
    }
    default:
      return state;
  }
};

export default searchReducer;
