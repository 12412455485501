import api from "../../../utils/helpers/api";

const getAchievements = (params) => {
  const option = {
    method: "get",
    url: `achievements/${params?.member_hid}`,
    params
  };
  return api(option);
};
export const getInformationAccount = () => {
  const option = {
    method: "get",
    url: `info`
  };
  return api(option);
};
const getDiamondHistory = (params = {}) => {
  const option = {
    method: "get",
    url: `diamond-history`,
    params
  };
  return api(option);
};
const updateProfile = (data) => {
  return api({
    method: "put",
    url: "profile",
    data
  });
};
const getExamHistory = () => {
  const option = {
    method: "get",
    url: `learning-history`
  };
  return api(option);
};
const postGiftcode = (data = {}) => {
  const option = {
    method: "post",
    url: `giftcode`,
    data
  };
  return api(option);
};
const getGiftcode = () => {
  const option = {
    method: "get",
    url: `giftcodes`
  };
  return api(option);
};
const getMissions = () => {
  const option = {
    method: "get",
    url: `missions`
  };
  return api(option);
};
const postMissionCompleted = (data = {}) => {
  const option = {
    method: "post",
    url: `mission-completed`,
    data
  };
  return api(option);
};
const postRefcode = (data = {}) => {
  const option = {
    method: "post",
    url: `refcode`,
    data
  };
  return api(option);
};
const postNotificationConfig = (data = {}) => {
  const option = {
    method: "post",
    url: `notifications/config`,
    data
  };
  return api(option);
};
const getGoals = (params = {}) => {
  const option = {
    method: "get",
    url: `goals`,
    params
  };
  return api(option);
};
const postGoals = (data = {}) => {
  const option = {
    method: "post",
    url: `goals`,
    data
  };
  return api(option);
};
const deleteAccount = (data = {}) => {
  const option = {
    method: "delete",
    url: `delete`,
    data
  };
  return api(option);
};
const getProfile = (params = {}, data = {}) => {
  return api({
    method: "put",
    url: "profile",
    params,
    data
  });
};
const getInfo = (params) => {
  return api({
    method: "get",
    url: `info/${params}`,
    params
  });
};
const getUserExtraInfo = (params) => {
  return api({
    method: "get",
    url: `extra-info/${params}`,
    params
  });
};
const giftDiamond = (data) => {
  return api({
    method: "post",
    url: `diamond/${data.member_hid}/give`,
    data
  });
};
export const accountServices = {
  getAchievements,
  getInformationAccount,
  getDiamondHistory,
  updateProfile,
  getExamHistory,
  postGiftcode,
  getGiftcode,
  getMissions,
  postMissionCompleted,
  postRefcode,
  postNotificationConfig,
  getGoals,
  postGoals,
  getInfo,
  deleteAccount,
  getProfile,
  getUserExtraInfo,
  giftDiamond,
};
