import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { LIST_CLASSES } from "../../Default/layout/constants";
import { toastSuccess } from "../components/reactToastify";
import { getExamLessonHistoryList, getTopExtraInfos } from "../utils/commonAPI";
import { ACTION_TYPES } from "./actionTypes";
import { commonServices } from "./services";

function* fetchGrades() {
  try {
    const response = yield call(commonServices.getGrades);
    if (response) {
      const listGrades = LIST_CLASSES.filter((ele) => {
        return response?.data?.data?.latest_data?.items.find((element: { id: number }) => element.id === ele.value);
      });
      yield put({
        type: ACTION_TYPES.GET_GRADE_SUCCESS,
        params: listGrades
      });
    }
  } catch (err) {}
}
function* fetchTop(action) {
  try {
    const response = yield call(commonServices.getTopInfos, action.params);
    if (response?.data?.data) {
      yield put({
        type: ACTION_TYPES.GET_TOP_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err) {}
}
function* fetchStaticData(action: { params: {} | undefined }) {
  try {
    const res = yield call(commonServices.getStaticData, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_STATIC_DATA_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}

function* completeMission(action: { params: any }) {
  try {
    const res = yield call(commonServices.completeMission, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.COMPLETE_MISSION_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.COMPLETE_MISSION_FAILED,
      params: error?.data?.error
    });
  }
}
function* getListSubject(action: { params: any }) {
  try {
    const res = yield call(commonServices.getListSubjects, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_SUBJECTS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {}
}
function* getNotifications() {
  try {
    const res = yield call(commonServices.getNotifications);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_NOTIFICATION_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {}
}
function* updateLocation(action) {
  try {
    const res = yield call(commonServices.updateLocation, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.UPDATE_LOCATION_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (error) {}
}
function* getSuggestedFriend() {
  try {
    const res = yield call(commonServices.getSuggestedFriend);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_SUGGESTED_FRIEND_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (error) {}
}
function* getLearningHistory() {
  try {
    const res = yield call(getExamLessonHistoryList);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_LEARNING_HISTORY_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* getNews() {
  try {
    const res = yield call(commonServices.getNews);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_NEWS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}

function* getTimeServer() {
  try {
    const res = yield call(commonServices.getTime);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_TIME_SERVER_SUCCESS,
        params: res?.data
      });
    }
  } catch (err) {}
}
function* getUserLanguageOptions() {
  try {
    const res = yield call(commonServices.getUserLanguageOptions);
    if (res) {
      yield put({
        type: ACTION_TYPES.HANDLE_GET_LANGUAGE_OPTIONS_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* updateUserLanguage(action) {
  try {
    const res = yield call(commonServices.updateUserLanguage, action?.data);
    if (res) {
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err) {}
}
function* getExamTopicHistory(action) {
  try {
    const res = yield call(commonServices.getExamTopicHistory, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_LIST_EXAM_HISTORY_SUCCESS,
        params: res?.data?.data?.exams_history,
        topic_slug: action.params?.topic_slug
      });
    }
  } catch (err) {}
}
function* postActionClickAdsInHouse(action) {
  try {
    const res = yield call(commonServices.postClickAdsInHouse, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.POSITION_ADS_IN_HOUSE_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {}
}
function* handleGetAds(action) {
  try {
    const response = yield call(commonServices.getAdsInHouse, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_ADS_IN_HOUSE_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err) {
    // yield put({
    //   type: ACTION_TYPES_HOME.GET_ADS_IN_HOUSE_FAILED,
    //   params: err?.data?.error
    // });
  }
}
function* handleGetUserInfo() {
  try {
    const response = yield call(commonServices.getInformationAccount);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_USER_INFO_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (err) {}
}
function* fetchTopExtra(action) {
  try {
    const response = yield call(getTopExtraInfos, action.params);
    if (response?.data?.data) {
      yield put({
        type: ACTION_TYPES.GET_TOP_EXTRA_SUCCESS,
        params: { ...response?.data?.data, grade: action.params?.grade }
      });
    }
  } catch (err) {
    yield put({ type: ACTION_TYPES.GET_TOP_EXTRA_FAILED, params: err?.data?.error });
  }
}

function* getDailyReport(action) {
  try {
    const [dailyReportResponse, learnedHistoryResponse] = yield all([
      call(commonServices.getDailyReport),
      call(getExamLessonHistoryList, action.params)
    ]);
    if (dailyReportResponse && learnedHistoryResponse) {
      yield put({
        type: ACTION_TYPES.GET_DAILY_REPORT_SUCCESS,
        params: { ...dailyReportResponse.data.data, ...learnedHistoryResponse.data.data }
      });
      action.successCallback();
    }
  } catch (error) {
    yield put({ type: ACTION_TYPES.GET_DAILY_REPORT_FAILED, params: error?.data?.error });
  }
}

function* commonSaga() {
  yield takeEvery(ACTION_TYPES.GET_GRADE, fetchGrades);
  yield takeEvery(ACTION_TYPES.GET_TOP, fetchTop);
  yield takeEvery(ACTION_TYPES.GET_STATIC_DATA, fetchStaticData);
  yield takeEvery(ACTION_TYPES.COMPLETE_MISSION, completeMission);
  yield takeEvery(ACTION_TYPES.GET_SUBJECTS, getListSubject);
  yield takeLatest(ACTION_TYPES.GET_NOTIFICATION, getNotifications);
  yield takeLatest(ACTION_TYPES.UPDATE_LOCATION, updateLocation);
  yield takeLatest(ACTION_TYPES.GET_SUGGESTED_FRIEND, getSuggestedFriend);
  yield takeLatest(ACTION_TYPES.GET_LEARNING_HISTORY, getLearningHistory);
  yield takeEvery(ACTION_TYPES.GET_NEWS, getNews);
  yield takeEvery(ACTION_TYPES.GET_TIME_SERVER, getTimeServer);
  yield takeEvery(ACTION_TYPES.HANDLE_GET_LANGUAGE_OPTIONS, getUserLanguageOptions);
  yield takeLatest(ACTION_TYPES?.HANDLE_UPDATE_LANGUAGE_USER, updateUserLanguage);
  yield takeEvery(ACTION_TYPES?.GET_LIST_EXAM_HISTORY, getExamTopicHistory);
  yield takeEvery(ACTION_TYPES.POST_ACTION_CLICK_ADS_IN_HOUSE, postActionClickAdsInHouse);
  yield takeLatest(ACTION_TYPES.GET_ADS_IN_HOUSE, handleGetAds);
  yield takeEvery(ACTION_TYPES.GET_USER_INFO, handleGetUserInfo);
  yield takeEvery(ACTION_TYPES.GET_TOP_EXTRA, fetchTopExtra);
  yield takeLatest(ACTION_TYPES.GET_DAILY_REPORT, getDailyReport);
}
export default commonSaga;
