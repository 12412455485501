import React from "react";
/* eslint-disable react/require-default-props */
type TypeIconHomeActive = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};
export default function IconHomeActive({
  className,
  fill,
  width,
  height
}: TypeIconHomeActive) {
  return (
    <svg
      className={className}
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0383 6.81969L14.2783 2.78969C12.7083 1.68969 10.2983 1.74969 8.78828 2.91969L3.77828 6.82969C2.77828 7.60969 1.98828 9.20969 1.98828 10.4697V17.3697C1.98828 19.9197 4.05828 21.9997 6.60828 21.9997H17.3883C19.9383 21.9997 22.0083 19.9297 22.0083 17.3797V10.5997C22.0083 9.24969 21.1383 7.58969 20.0383 6.81969ZM12.7483 17.9997C12.7483 18.4097 12.4083 18.7497 11.9983 18.7497C11.5883 18.7497 11.2483 18.4097 11.2483 17.9997V14.9997C11.2483 14.5897 11.5883 14.2497 11.9983 14.2497C12.4083 14.2497 12.7483 14.5897 12.7483 14.9997V17.9997Z"
        fill="white"
      />
    </svg>
  );
}
