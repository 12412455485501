export const LIST_ROOM_PAGE_QUERY = "phong-thi";
export const RANK_PAGE_QUERY = "xep-hang";
export const BADGES_PAGE_QUERY = "huy-hieu";
export const HISTORY_PAGE_QUERY = "lich-su";

export const arrayMenuHeaderArena = [
  {
    id: 1,
    title: "Phòng thi",
    messageId: "Arena.tab_title.room_overview",
    link: "thi-online/phong-thi",
    query: LIST_ROOM_PAGE_QUERY
  },
  {
    id: 2,
    title: "Bảng vàng",
    messageId: "Arena.tab_title.rank",
    link: "thi-online/xep-hang",
    query: RANK_PAGE_QUERY
  },
  {
    id: 3,
    title: "Huy hiệu",
    messageId: "Arena.tab_title.badges",
    link: "thi-online/huy-hieu",
    query: BADGES_PAGE_QUERY
  },
  {
    id: 4,
    title: "Lịch sử",
    messageId: "Arena.tab_title.room_history",
    link: "thi-online/lich-su",
    query: HISTORY_PAGE_QUERY
  }
];

export const arrayMenuRoomArena = [
  {
    id: 1,
    messageId: "Arena.room_overview_all",
    filterType: "all",
    category: "all"
  },
  {
    id: 2,
    messageId: "Arena.room_overview_mine",
    filterType: "mine",
    category: "my-room"
  },
  {
    id: 3,
    messageId: "Arena.room_overview_upcoming",
    filterType: "upcoming",
    category: "waiting-room"
  }
];

export const ADD_MINUTES_MIN_START_TIME = 3;
export const ADD_DAYS_MAX_START_TIME = 1;
export const ADD_DAYS_MAX_END_TIME = 3;
export const NUM_OF_ROOM_LOAD_MORE = 20;
export const NUMBER_DEBOUNCE = 300;
export const API_STATUS_SUCCESS = "success";
export const API_STATUS_ERROR = "error";

export const ERROR_CODE = {
  CREATE_ROOM_NOT_ENOUGH_DIAMOND: "EBADND",
  GET_DETAIL_ROOM_NOT_FOUND: "",
  DELETE_ROOM_NOT_FOUND: "ENFARO",
  GET_ROOM_DETAIL_NOT_FOUND: "ENFINT",
  UPDATE_ROOM_ONLY_ONE: "ENAEOO",
  UPDATE_ROOM_NOT_ACCEPTABLE_TIME: "ENACNE",
  NOT_FOUND_SUBMITTED_AT_TIME: "ENASAT",
  TOKEN_QUIZ_EXPIRED_INTERNAL: "ETKQEI",
  NOT_FOUND_ARENA_QUIZ: "ENFAQU",
  ARENA_ROOM_READY_START_ON_OTHER_DEVICE: "ENAOOD",
  ARENA_ROOM_NOT_START: "ENFARM",
  NOT_FOUND_ARENA_ROOM_MEMBER: "ENFQUI",
  NOT_FOUND_QUIZ: "ENFQUI",
  NOT_ACCEPTABLE_SUBMITTED: "ENASMT",
  NOT_ENOUGH_TIME_START_QUIZ: "ENANET",
  NOT_FOUND_MEMBER_IN_ROOM: "ENFARM",
  NOT_ACCEPTABLE_DELETE_ROOM_HAS_MEMBER: "ENADRM"
};
export const LIST_ERROR_CODE_QUIZ_START_TO_CLEAR_DEEP_TIME = [
  ERROR_CODE.NOT_FOUND_ARENA_QUIZ,
  ERROR_CODE.ARENA_ROOM_READY_START_ON_OTHER_DEVICE,
  ERROR_CODE.ARENA_ROOM_NOT_START,
  ERROR_CODE.NOT_FOUND_ARENA_ROOM_MEMBER,
  ERROR_CODE.NOT_FOUND_QUIZ,
  ERROR_CODE.NOT_ACCEPTABLE_SUBMITTED,
  ERROR_CODE.NOT_ENOUGH_TIME_START_QUIZ
];

export const listButtonFilterDate = [
  {
    title: "Arena.rank_lifetime",
    value: "lifetime"
  },
  {
    title: "Arena.rank_weekly",
    value: "weekly"
  },
  {
    title: "Arena.rank_monthly",
    value: "monthly"
  }
];

export const listSort = (grade) => {
  return [
    {
      label: "Mới hơn",
      value: `${grade}_newest`
    },
    {
      label: "Cũ hơn",
      value: `${grade}_oldest`
    },
    {
      label: "Đông người nhất",
      value: `${grade}_most_crowded`
    },
    {
      label: "Rank cao nhất",
      value: `${grade}_ranking`
    }
  ];
};
export const ERROR_CODE_LIST_POPUP_RETRY = [
  "EARIPW",
  "EBADSE",
  "ENFMEM",
  "ENADAM",
  "ENOTAC",
  "ENAMEM"
];

export const TYPE_EXAM = {
  NORMAL: "Đề chuẩn",
  PREMIUM: "Đề Primary - VIP"
};

export const TOPIC_CREATION_FEE_PREMIUM_RATE = 1.2;

export const TYPE_EXAM_PREMIUM_VALUE = 1;
export const ERROR_CODE_NEED_VIP = "ENOVIP";
