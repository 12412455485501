import api from "../../../utils/helpers/api";
import apiAdsInHouse from "../../../utils/helpers/apiAdsInHouse";

const getGrades = () => {
  const option = {
    method: "get",
    url: `grades`
  };
  return api(option) as Promise<any>;
};
const getTopInfos = (params = {}) => {
  const option = {
    method: "get",
    url: "top",
    params
  };
  return api(option);
};
const getStaticData = (params = {}) => {
  const option = {
    method: "get",
    url: `statistics`,
    params
  };
  return api(option);
};
const completeMission = (data) => {
  const option = {
    method: "post",
    url: "mission-completed",
    data
  };
  return api(option);
};
const getListSubjects = (params) => {
  const option = {
    method: "get",
    url: `grades/${params}/subjects`
  };
  return api(option);
};
const getNotifications = () => {
  const option = {
    method: "get",
    url: `notifications`
  };
  return api(option);
};
const updateLocation = (data) => {
  const option = {
    method: "put",
    url: "location",
    data
  };
  return api(option);
};
const getSuggestedFriend = () => {
  const option = {
    method: "get",
    url: "friends/suggest"
  };
  return api(option);
};
const getNews = () => {
  return api({
    method: "get",
    url: "news"
  });
};
const getTime = () => {
  return api({
    method: "get",
    url: "check"
  });
};
const getUserLanguageOptions = () => {
  return api({
    method: "get",
    url: "user-language"
  });
};
const updateUserLanguage = (data) => {
  return api({
    method: "put",
    url: "user-language",
    data
  });
};
const getExamTopicHistory = (params) => {
  return api({
    method: "get",
    url: `topics/${params?.topic_slug}/exam-topic-history`,
    params
  });
};
const postClickAdsInHouse = (data) => {
  return apiAdsInHouse({
    method: "post",
    url: `ads/${data.activity_uid}/action`,
    data
  });
};
export const getInformationAccount = () => {
  const option = {
    method: "get",
    url: `info`
  };
  return api(option);
};
const getAdsInHouse = (params) => {
  return apiAdsInHouse({
    method: "get",
    url: "ads",
    params
  });
};
const getDailyReport = () => {
  const option = {
    method: "get",
    url: "daily-report"
  };
  return api(option);
};
export const commonServices = {
  getGrades,
  getTopInfos,
  getStaticData,
  completeMission,
  getListSubjects,
  getNotifications,
  updateLocation,
  getSuggestedFriend,
  getNews,
  getTime,
  getUserLanguageOptions,
  updateUserLanguage,
  getExamTopicHistory,
  postClickAdsInHouse,
  getInformationAccount,
  getAdsInHouse,
  getDailyReport
};
