import { put, call, takeLatest, debounce } from "redux-saga/effects";
import { toastSuccess } from "../../common/components/reactToastify";
import { DEBOUNCE_TIMES_API } from "../../common/utils/constant";
import ACTION_TYPES from "./actionType";
import { friendServices } from "./services";

function* handleGetInvitationFriend() {
  try {
    const res = yield call(friendServices.getInvitationFriend);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_INVITATION_FRIEND_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err: any) {}
}
function* handleSearchFriend(action) {
  try {
    const res = yield call(friendServices.searchFriend, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.SEARCH_FRIEND_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.SEARCH_FRIEND_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleDeclineFriendRequest(action) {
  try {
    const res = yield call(friendServices.declineFriendRequest, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.DECLINE_FRIEND_REQUEST_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.DECLINE_FRIEND_REQUEST_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleInviteFriend(action) {
  try {
    const res = yield call(friendServices.inviteFriend, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.INVITE_FRIEND_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.INVITE_FRIEND_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleDeleteInvitation(action) {
  try {
    const res = yield call(friendServices.deleteInvitation, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.DELETE_INVITATION_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.DELETE_INVITATION_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleAcceptFriend(action) {
  try {
    const res = yield call(friendServices.acceptFriend, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.ACCEPT_FRIEND_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.ACCEPT_FRIEND_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleRemoveFriend(action) {
  try {
    const res = yield call(friendServices.removeFriend, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.REMOVE_FRIEND_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.REMOVE_FRIEND_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleFollowFriend(action) {
  try {
    const res = yield call(friendServices.followFriend, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.FOLLOW_FRIEND_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.FOLLOW_FRIEND_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleDeleteFollow(action) {
  try {
    const res = yield call(friendServices.deleteFollow, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.DELETE_FOLLOW_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.DELETE_FOLLOW_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleGetListFriend(action) {
  try {
    const res = yield call(friendServices.getListFriend, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_LIST_FRIEND_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_LIST_FRIEND_FAILED,
      params: err?.data?.error
    });
  }
}

function* friendSagas() {
  yield takeLatest(ACTION_TYPES.GET_INVITATION_FRIEND, handleGetInvitationFriend);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.SEARCH_FRIEND, handleSearchFriend);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.DECLINE_FRIEND_REQUEST, handleDeclineFriendRequest);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.INVITE_FRIEND, handleInviteFriend);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.DELETE_INVITATION, handleDeleteInvitation);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.ACCEPT_FRIEND, handleAcceptFriend);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.REMOVE_FRIEND, handleRemoveFriend);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.FOLLOW_FRIEND, handleFollowFriend);
  yield debounce(DEBOUNCE_TIMES_API, ACTION_TYPES.DELETE_FOLLOW, handleDeleteFollow);
  yield takeLatest(ACTION_TYPES.GET_LIST_FRIEND, handleGetListFriend);
}
export default friendSagas;
