/* eslint-disable react/require-default-props */
import React from "react";

// eslint-disable-next-line no-unused-vars
type IconStatistic = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export default function IconStatistic({
  className,
  fill,
  width,
  height
}: IconStatistic) {
  return (
    <svg
      className={className}
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33419 10.8027H5.60447C4.71777 10.8027 4 11.5205 4 12.4072V19.6133H9.33419V10.8027V10.8027Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0641 4.39844H10.9248C10.0381 4.39844 9.32031 5.11626 9.32031 6.00295V19.5989H14.6545V6.00295C14.6545 5.11626 13.9508 4.39844 13.0641 4.39844Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3938 13.1953H14.6641V19.5993H19.9983V14.7998C19.9842 13.9131 19.2664 13.1953 18.3938 13.1953Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
