/* eslint-disable react/require-default-props */
import React from "react";

// eslint-disable-next-line no-unused-vars
type IconStatisticActive = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export default function IconStatisticActive({
  className,
  fill,
  width,
  height
}: IconStatisticActive) {
  return (
    <svg
      className={className}
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 14.667H7.33857C6.23039 14.667 5.33331 15.6445 5.33331 16.8522V26.667H12V14.667Z"
        fill="white"
        stroke="#D82332"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6148 5.3335H14.4063C13.0765 5.3335 12 6.34093 12 7.58537V26.6668H20V7.58537C20 6.34093 18.9446 5.3335 17.6148 5.3335Z"
        fill="white"
        stroke="#D82332"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6614 17.3335H20V26.6668H26.6667V19.672C26.6491 18.3797 25.752 17.3335 24.6614 17.3335Z"
        fill="white"
        stroke="#D82332"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
