/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import {
  activate,
  fetchAndActivate,
  fetchConfig,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";
import cookie from "js-cookie";
import * as React from "react";
import { useDispatch } from "react-redux";
import { commonActions } from "../features/common/redux/actions";
import { store } from "../../app/store";
import { TOKEN } from "../utils/constants/constants";
import firebase, { app } from "./firebaseConfig";

export const firebaseUnregister = async () => {
  if (firebase.messaging?.isSupported()) {
    const messaging = firebase.messaging();
    await messaging
      .requestPermission()
      ?.then(async () => {
        const token = await messaging.getToken();
        if (token) {
          // if (data.code === 200) {
          //    cookie.remove(TOKEN);
          // } else {
          //   message.error(data.message);
          // }
        }
      })
      .catch(async (err) => {
        // if (data.code === 200) {
        // cookie.remove(TOKEN);
        // window.location.href = '/login';
        // } else {
        // message.error(data.message);
        // }
      });
  } else {
    console.log("not support firebaseUnregister");
  }
};
export const firebaseRegister = async () => {
  if (firebase.messaging?.isSupported()) {
    const messaging = firebase.messaging();
    await messaging
      .requestPermission()
      .then(async () => {
        const token = await messaging.getToken();
        if (token) {
        }
      })
      .catch((err) => { });
  } else {
    console.log("not support firebaseRegister");
  }
};

// export const firebaseRemoteConfig = async () => {
//   const dispatch = useDispatch();
//   const remoteConfig = getRemoteConfig(app);
//   remoteConfig.settings.minimumFetchIntervalMillis = 3000;
//   fetchAndActivate(remoteConfig)
//     .then((data) => {
//       const val = getValue(remoteConfig, "ad_web_banner_top_header") r;
//       console.log(val);
//       dispatch(commonActions.handleSaveAdsFirebaseRemote(val?._value || false))
//     })
//     .catch((err) => {
//       // ...
//     });

//   fetchConfig(remoteConfig);
//   activate(remoteConfig);
// };
export const firebaseRemoteShowBannerDirect = async () => {
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 360000;
  fetchAndActivate(remoteConfig).then((data) => {
    const val = getValue(remoteConfig, "show_banner_responsive");
    store.dispatch(commonActions.handleShowDirectApp(val?._value || false))
  }).catch((err) => {
  })
  fetchConfig(remoteConfig);
  activate(remoteConfig);
}

function Firebase(props) {
  const dispatch = useDispatch();
  let messaging = null;
  if (firebase.messaging?.isSupported()) {
    messaging = firebase.messaging();
  }
  const firebaseRemoteConfig = async () => {
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 3000;
    fetchAndActivate(remoteConfig)
      .then((data) => {
        const val = getValue(remoteConfig, "ad_web_banner_top_header");
        console.log(val);
        dispatch(commonActions.handleSaveAdsFirebaseRemote(val?._value || false))
      })
      .catch((err) => {
        // ...
      });
    fetchConfig(remoteConfig);
    activate(remoteConfig);
  };


  React.useEffect(() => {
    firebaseRemoteConfig();
    if (cookie.get(TOKEN)) {
      firebaseRegister();
    } // eslint-disable-next-line
  }, [messaging]);

  React.useEffect(() => {
    if (firebase.messaging?.isSupported()) {
      messaging.onMessage((payload) => { });
    } else {
      console.log("not support messaging.onMessage");
    }

    // eslint-disable-next-line
  }, [messaging]);

  /* eslint-disable */
  return <>{props.children}</>;
}

// export default connect((state) => ({}), {
// actionGetAllNotify,
// })(Firebase);

export default Firebase;
