import { call, debounce, put, takeEvery } from "redux-saga/effects";
import { toastSuccess } from "../../common/components/reactToastify";
import { giftAction } from "./action";
import { ACTION_TYPES } from "./actionTypes";
import { giftService } from "./services";

function* handleGetGift() {
  try {
    const res = yield call(giftService.getGiftItem);
    if (res?.data?.data) {
      yield put(giftAction.handleGetGiftItemSuccess(res?.data?.data));
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_GIFT_ITEM_FAILED,
      params: err?.data?.error
    });
  }
}

function* handleGetGiftItemDetail(action) {
  try {
    const res = yield call(giftService.getGiftItemDetail, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_GIFT_ITEM_DETAIL_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_GIFT_ITEM_DETAIL_FAILED,
      params: err?.data?.error
    });
  }
}

function* handleGetGiftExchange(action) {
  try {
    const res = yield call(giftService.giftItemExchange, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE_SUCCESS,
        params: res?.data?.data
      });
      toastSuccess(res?.data?.data?.success_message);
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE_FAILED,
      params: err?.data?.error
    });
  }
}

function* handleGetGiftItemHistory() {
  try {
    const res = yield call(giftService.getGiftItemHistory);
    if (res) {
      yield put({
        type: ACTION_TYPES.GET_GIFT_ITEM_HISTORY_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err: any) {
    yield put({
      type: ACTION_TYPES.GET_GIFT_ITEM_HISTORY_FAILED,
      params: err?.data?.error
    });
  }
}

function* giftItemSaga() {
  yield takeEvery(ACTION_TYPES.GET_GIFT_ITEM, handleGetGift);
  yield takeEvery(ACTION_TYPES.GET_GIFT_ITEM_DETAIL, handleGetGiftItemDetail);
  yield debounce(500, ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE, handleGetGiftExchange);
  yield takeEvery(ACTION_TYPES.GET_GIFT_ITEM_HISTORY, handleGetGiftItemHistory);
}

export default giftItemSaga;
