export const getUniqueItems = (array1, array2) => {
  const newArray: any[] = [];
  array2.forEach((element) => {
    if (!array1.find((ele) => ele.id === element.id)) {
      newArray.push(element);
    }
  });
  return newArray;
};

export const getUniqueRoom = (array1: any, array2: any) => {
  const newArray: any[] = [];
  array2.forEach((element: any) => {
    if (!array1.find((ele) => ele.room_hid === element.room_hid)) {
      newArray.push(element);
    }
  });
  return newArray;
};
