import React from "react";

export default function IconExamsOnlineActive({ width, height, fill }: any) {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.7467 7.31984V8.3065L19.0267 5.57317C17.24 4.5465 14.7467 4.5465 12.9734 5.57317L8.25336 8.31984V7.31984C8.25336 4.31984 9.89336 2.6665 12.8934 2.6665H19.1067C22.1067 2.6665 23.7467 4.31984 23.7467 7.31984Z"
        fill="white"
      />
      <path
        d="M23.7867 10.6265L23.6 10.5332L21.7867 9.49316L18.0267 7.31982C16.88 6.65316 15.12 6.65316 13.9734 7.31982L10.2134 9.47982L8.40002 10.5465L8.16002 10.6665C5.82669 12.2398 5.66669 12.5332 5.66669 15.0532V21.0798C5.66669 23.5998 5.82669 23.8932 8.21335 25.5065L13.9734 28.8265C14.5467 29.1732 15.2667 29.3198 16 29.3198C16.72 29.3198 17.4534 29.1598 18.0267 28.8265L23.84 25.4665C26.1867 23.8932 26.3334 23.6132 26.3334 21.0798V15.0532C26.3334 12.5332 26.1734 12.2398 23.7867 10.6265ZM19.72 17.9998L18.9067 18.9998C18.7734 19.1465 18.68 19.4265 18.6934 19.6265L18.7734 20.9065C18.8267 21.6932 18.2667 22.0932 17.5334 21.8132L16.3467 21.3332C16.16 21.2665 15.8534 21.2665 15.6667 21.3332L14.48 21.7998C13.7467 22.0932 13.1867 21.6798 13.24 20.8932L13.32 19.6132C13.3334 19.4132 13.24 19.1332 13.1067 18.9865L12.28 17.9998C11.7734 17.3998 12 16.7332 12.76 16.5332L14 16.2132C14.2 16.1598 14.4267 15.9732 14.5334 15.8132L15.2267 14.7465C15.6534 14.0798 16.3334 14.0798 16.7734 14.7465L17.4667 15.8132C17.5734 15.9865 17.8134 16.1598 18 16.2132L19.24 16.5332C20 16.7332 20.2267 17.3998 19.72 17.9998Z"
        fill="white"
      />
    </svg>
  );
}
