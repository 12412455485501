import { put, call, takeEvery, takeLatest, debounce } from "redux-saga/effects";
import { store } from "../../../../app/store";
import { toastSuccess } from "../../common/components/reactToastify";
import { questionAndAnswerAction } from "./actions";
import { ACTION_TYPES } from "./actionTypes";
import { questionAndAnswerServices } from "./services";

function* handleGetListPost(action) {
  try {
    const response = yield call(questionAndAnswerServices.getListPost, action.params);
    yield put(questionAndAnswerAction.handleGetListPostSuccess(response?.data?.data));
  } catch (error) {
    yield put(questionAndAnswerAction.handleGetListPostFailed(error?.data?.error));
  }
}
function* handleGetDetailPost(action) {
  try {
    const response = yield call(questionAndAnswerServices.getDetailPost, action.params);
    yield put(questionAndAnswerAction.handleGetDetailPostSuccess(response?.data?.data));
  } catch (error) {
    yield put(questionAndAnswerAction.handleGetDetailPostFailed(error?.data?.error));
  }
}
function* handleVote(action) {
  try {
    const response = yield call(questionAndAnswerServices.votePost, action.params);
    yield put(questionAndAnswerAction.handleVoteSuccess(response?.data?.data));
    toastSuccess(
      response?.data?.data?.success_message,
      () => {},
      () => {},
      1000
    );
  } catch (error) {
    yield put(questionAndAnswerAction.handleVoteFailed(error?.data?.error));
  }
}
function* handleSavePost(action) {
  try {
    const response = yield call(questionAndAnswerServices.savePost, action.params);
    if (response) {
      yield put(questionAndAnswerAction.handleSavePostSuccess(response?.data?.data));
      toastSuccess(
        response?.data?.data?.success_message,
        () => {},
        () => store.dispatch(questionAndAnswerAction.handleCloseNotify()),
        500
      );
    }
  } catch (error) {
    yield put(questionAndAnswerAction.handleSavePostFailed(error?.data?.error));
  }
}
function* handleGetCreationInfo() {
  try {
    const response = yield call(questionAndAnswerServices.getCreationInfo);
    if (response) {
      yield put(questionAndAnswerAction.handleGetCreationInfoSuccess(response?.data?.data));
    }
    toastSuccess(response?.data?.data?.success_message);
  } catch (error) {
    yield put(questionAndAnswerAction.handleGetCreationInfoFailed(error?.data?.error));
  }
}
function* handleGetQARankings(action) {
  try {
    const response = yield call(questionAndAnswerServices.getQARankings, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_QA_RANKINGS_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.GET_QA_RANKINGS_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleGetCreationInfoSubscribe(action) {
  try {
    const response = yield call(
      questionAndAnswerServices.getCreationInfoSubscribe,

      action?.params
    );
    if (response) {
      yield put({
        type: ACTION_TYPES.GET_CREATION_INFO_SUBSCRIBE_SUCCESS,
        params: response?.data?.data
      });
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.GET_CREATION_INFO_SUBSCRIBE_FAILED,
      params: error?.data?.error
    });
  }
}
function* handlePostSubscribeTopic(action) {
  try {
    const response = yield call(questionAndAnswerServices.postSubscribeTopic, action?.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.POST_SUBSCRIBE_TOPIC_SUCCESS,
        params: response?.data?.data
      });
    }
    toastSuccess(response?.data?.data?.success_message);
  } catch (error) {
    yield put({
      type: ACTION_TYPES.POST_SUBSCRIBE_TOPIC_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleClosePost(action) {
  try {
    const response = yield call(questionAndAnswerServices.closePost, action.params);
    yield put({
      type: ACTION_TYPES.HANDLE_CLOSE_POST_SUCCESS,
      params: response?.data?.data
    });
    toastSuccess(response?.data?.data?.success_message);
    action.callback();
  } catch (error) {
    yield put({
      type: ACTION_TYPES.HANDLE_CLOSE_POST_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleDeletePost(action) {
  try {
    const res = yield call(questionAndAnswerServices.deletePost, action.params);
    if (res) {
      yield put({
        type: ACTION_TYPES.HANDLE_DELETE_POST_SUCCESS,
        params: res?.data?.data
      });
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.HANDLE_DELETE_POST_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleComplain(action) {
  try {
    const response = yield call(questionAndAnswerServices.complainPost, action.params);
    yield put({
      type: ACTION_TYPES.HANDLE_COMPLAIN_SUCCESS,
      params: response?.data?.data
    });
    toastSuccess(response?.data?.data?.success_message);
  } catch (error) {
    yield put({
      type: ACTION_TYPES.HANDLE_COMPLAIN_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleCreatePost(action) {
  try {
    const response = yield call(questionAndAnswerServices.createPost, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.CREATE_POST_SUCCESS,
        params: response?.data?.data
      });
      // toastSuccess(response?.data?.data?.success_message);
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.CREATE_POST_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleVoteComment(action) {
  try {
    const response = yield call(questionAndAnswerServices.votePost, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.HANDLE_VOTE_COMMENT_SUCCESS,
        params: response?.data?.data
      });
      toastSuccess(response?.data?.data?.success_message);
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES.HANDLE_VOTE_COMMENT_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleComment(action) {
  try {
    const response = yield call(questionAndAnswerServices.comment, action.params, action.id);
    if (response) {
      yield put({
        type: ACTION_TYPES.HANDLE_COMMENT_SUCCESS,
        params: response?.data?.data
      });
    }
    toastSuccess(response?.data?.data?.success_message);
    action.callback();
  } catch (err) {
    yield put({
      type: ACTION_TYPES.HANDLE_COMMENT_FAILED,
      params: err?.data?.error
    });
  }
}
function* handleEditPost(action) {
  try {
    const response = yield call(questionAndAnswerServices.editPost, action.params, action.postId);
    if (response) {
      yield put({
        type: ACTION_TYPES.EDIT_POST_SUCCESS,
        params: response.data?.data
      });
    }
    toastSuccess(response?.data?.data?.success_message);
    action.callback();
  } catch (error) {
    yield put({
      type: ACTION_TYPES.EDIT_POST_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleDeleteComment(action) {
  try {
    const response = yield call(questionAndAnswerServices.deleteComment, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.DELETE_COMMENT_SUCCESS,
        params: response.data?.data
      });
    }
    toastSuccess(response?.data?.data?.success_message);
    action.callback();
  } catch (error) {
    yield put({
      type: ACTION_TYPES.DELETE_COMMENT_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleEditComment(action) {
  try {
    const response = yield call(questionAndAnswerServices.editComment, action.params, action.id);
    if (response) {
      yield put({
        type: ACTION_TYPES.EDIT_COMMENT_SUCCESS,
        params: response.data?.data
      });
      toastSuccess(response?.data?.data?.success_message);
      action.callback();
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.EDIT_COMMENT_FAILED,
      params: error?.data?.error
    });
  }
}
function* handleAcceptComment(action) {
  try {
    const response = yield call(questionAndAnswerServices.acceptComment, action.params);
    if (response) {
      yield put({
        type: ACTION_TYPES.ACCEPT_COMMENT_SUCCESS,
        params: response.data?.data
      });
      toastSuccess(response?.data?.data?.success_message);
      action.callback();
    }
  } catch (error) {
    yield put({
      type: ACTION_TYPES.ACCEPT_COMMENT_FAILED,
      params: error?.data?.error
    });
  }
}

function* questionAndAnswerSaga() {
  yield takeEvery(ACTION_TYPES.GET_LIST_POST, handleGetListPost);
  yield takeEvery(ACTION_TYPES.GET_DETAIL_POST, handleGetDetailPost);
  yield takeLatest(ACTION_TYPES.HANDLE_VOTE, handleVote);
  yield takeLatest(ACTION_TYPES.HANDLE_SAVE_POST, handleSavePost);
  yield takeLatest(ACTION_TYPES.GET_CREATION_INFO_POST, handleGetCreationInfo);
  yield takeLatest(ACTION_TYPES.GET_QA_RANKINGS, handleGetQARankings);
  yield takeEvery(ACTION_TYPES.GET_CREATION_INFO_SUBSCRIBE, handleGetCreationInfoSubscribe);
  yield takeLatest(ACTION_TYPES.POST_SUBSCRIBE_TOPIC, handlePostSubscribeTopic);
  yield takeLatest(ACTION_TYPES.HANDLE_CLOSE_POST, handleClosePost);
  yield takeLatest(ACTION_TYPES.HANDLE_DELETE_POST, handleDeletePost);
  yield takeLatest(ACTION_TYPES.HANDLE_COMPLAIN, handleComplain);
  yield debounce(300, ACTION_TYPES.CREATE_POST, handleCreatePost);
  yield takeLatest(ACTION_TYPES.EDIT_POST, handleEditPost);
  yield takeLatest(ACTION_TYPES.HANDLE_VOTE_COMMENT, handleVoteComment);
  yield debounce(300, ACTION_TYPES.HANDLE_COMMENT, handleComment);
  yield takeLatest(ACTION_TYPES.DELETE_COMMENT, handleDeleteComment);
  yield takeLatest(ACTION_TYPES.EDIT_COMMENT, handleEditComment);
  yield takeLatest(ACTION_TYPES.ACCEPT_COMMENT, handleAcceptComment);
}
export default questionAndAnswerSaga;
