/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable prefer-const */
import "../styles/globals.css";
import "../styles/common.scss";
import "../components/features/Default/layout/style.module.scss";
import type { AppProps } from "next/app";
import App from "next/app";
import * as locales from "@mui/material/locale";
import { DefaultSeo } from "next-seo";
import { Provider } from "react-redux";
import parser from "ua-parser-js";
import mediaQuery from "css-mediaquery"; // 2.
import { PersistGate } from "redux-persist/integration/react";
import ThemeProvider from "@mui/styles/ThemeProvider";
import { useRouter } from "next/router";
import { getCookie, setCookie } from "cookies-next";
import { END } from "redux-saga";
import React from "react";
import { createTheme } from "@mui/material/styles";
import LocaleProvider from "../components/utils/localeProvider/LocaleProvider";
import Firebase from "../components/firebase/Firebase";
import { wrapper } from "../app/store";
import { THEME } from "../components/utils/configs/setupTheme";
import { AuthProvider } from "../components/features/common/hooks/useAuth";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import { appMetaDescription } from "../components/utils/constants/env";
import { LOCALES, isEmpty } from "../components/utils/constants/constants";
import ScrollTop from "../components/features/common/components/ScrollTop";
import { commonActions } from "../components/features/common/redux/actions";
import { arenaRoomActions } from "../components/features/arena/redux/actions";
import { accountActions } from "../components/features/account/redux/actions";
import { quizActions } from "../components/features/topics/redux/actions";
import { questionAndAnswerAction } from "../components/features/questionAndAnswer/redux/actions";
import authService from "../components/utils/auth/authService";
import { BASE_LANGUAGES } from "../components/features/common/utils/constant";

function MyApp({ Component, ...rest }: AppProps) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const { deviceType, pageProps } = props;

  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: deviceType === "mobile" ? "320px" : "1100px"
    })
  });

  const muiTheme = createTheme(
    {
      ...THEME,
      components: {
        ...THEME.components,
        MuiUseMediaQuery: {
          defaultProps: {
            ssrMatchMedia
          }
        }
      }
    },
    locales[LOCALES]
  );

  const router = useRouter();
  // const mobile = useMediaQuery("(max-width:480px)");
  React.useEffect(() => {
    const userLanguage = store.getState()?.commonNew?.userLanguage;
    if (isEmpty(userLanguage)) {
      // set default
      const defaultLanguage = BASE_LANGUAGES.includes(navigator.language?.split("-")[0])
        ? navigator.language?.split("-")[0]
        : BASE_LANGUAGES[0];
      store.dispatch(commonActions.handleSaveUserLanguage(defaultLanguage));
      setCookie("userLanguage", JSON.stringify(defaultLanguage));
    }
  }, []);
  const app = (
    <ThemeProvider theme={muiTheme}>
      <AuthProvider>
        <DefaultSeo
          openGraph={{
            type: "website",
            url: "jlptup.com",
            site_name: "JlptUp",
            title: "Jlpt Up - Học, Thi Đấu online",
            description: appMetaDescription
          }}
        />
        <ScrollTop>
          <Component {...pageProps} key={router.route} />
        </ScrollTop>
      </AuthProvider>
    </ThemeProvider>
  );
  return (
    <Provider store={store}>
      <LocaleProvider>
        <Firebase>
          {typeof window === "undefined" ? (
            app
          ) : (
            // eslint-disable-next-line no-underscore-dangle
            <PersistGate loading={null} persistor={store.__persistor}>
              {app}
            </PersistGate>
          )}
        </Firebase>
      </LocaleProvider>
    </Provider>
  );
}

MyApp.getInitialProps = wrapper.getInitialPageProps((store) => async (context: any) => {
  const { ctx } = context;
  const { req, res } = ctx;
  const deviceType = parser(ctx?.req?.headers["user-agent"])?.device?.type || "desktop";
  const pageProps = {
    // https://nextjs.org/docs/advanced-features/custom-app#caveats
    ...(await App.getInitialProps(context as any)).pageProps
  };

  const accessTokenCookies = JSON.parse((getCookie("accessToken", { req, res }) as string) || "{}");
  const refreshTokenCookies = JSON.parse((getCookie("refreshToken", { req, res }) as string) || "{}");
  const userInfo = JSON.parse((getCookie("userInfo", { req, res }) as string) || "{}");
  const ServerError = JSON.parse((getCookie("ServerError", { req, res }) as string) || "{}");

  // clear all notify
  store.dispatch(commonActions.deleteNotify());
  store.dispatch(arenaRoomActions.handleDeleteNotify());
  store.dispatch(accountActions.handleClearNotify());
  store.dispatch(quizActions.handleCleanNotify());
  store.dispatch(questionAndAnswerAction.handleCloseNotify());

  if (userInfo !== store?.getState()?.commonNew?.userInfos) {
    store.dispatch(commonActions.addUserInfos(userInfo));
  }

  if (!isEmpty(userInfo?.token?.access_token)) {
    setCookie("accessToken", JSON.stringify(userInfo?.token?.access_token), { req, res });
    setCookie("refreshToken", JSON.stringify(userInfo?.token?.ref_token), { req, res });
    store.dispatch(commonActions.handleSaveAccessToken(userInfo?.token?.access_token));
    store.dispatch(commonActions.handleSaveRefreshToken(userInfo?.token?.ref_token));
  } else if (!isEmpty(accessTokenCookies) && isEmpty(ServerError)) {
    store.dispatch(commonActions.handleSaveAccessToken(accessTokenCookies));
    store.dispatch(commonActions.handleSaveRefreshToken(refreshTokenCookies));
  } else {
    setCookie("ServerError", "{}");
    // eslint-disable-next-line no-unused-vars
    Promise.all([await authService(store)]).then((value: any) => {
      setCookie("accessToken", JSON.stringify(store?.getState()?.commonNew?.accessToken), { req, res });
    });
  }

  const gradeCookie = JSON.parse((ctx?.req?.cookies.grade as string) || "{}");
  if (!isEmpty(gradeCookie) && gradeCookie !== store?.getState()?.commonNew?.grade) {
    store.dispatch(commonActions.handleChooseGrade(gradeCookie));
  }
  if (isEmpty(gradeCookie)) {
    setCookie("grade", JSON.stringify(store?.getState()?.commonNew?.grade), { req, res });
  }
  const userLanguage = JSON.parse((ctx?.req?.cookies.userLanguage as string) || "{}");  
  if (!isEmpty(userLanguage)) {
    store.dispatch(commonActions.handleSaveUserLanguage(userLanguage));
  }

  if (context.ctx.req) {
    store.dispatch(END);
    await store?.sagaTask.toPromise();
  }
  return {
    ...pageProps,
    deviceType
  };
});
export default wrapper.withRedux(MyApp);
