/* eslint-disable react/require-default-props */
import React from "react";

export default function IconHome({ width, height, fill }: any) {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 32 32"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0267 3.78672L4.83999 9.38672C3.63999 10.3201 2.66666 12.3067 2.66666 13.8134V23.6934C2.66666 26.7867 5.18666 29.3201 8.27999 29.3201H23.72C26.8133 29.3201 29.3333 26.7867 29.3333 23.7067V14.0001C29.3333 12.3867 28.2533 10.3201 26.9333 9.40005L18.6933 3.62672C16.8267 2.32005 13.8267 2.38672 12.0267 3.78672Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23.9868V19.9868"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
