export const ACTION_TYPES = {
  GET_DETAIL_ROOM: "GET_DETAIL_ROOM",
  GET_DETAIL_ROOM_SUCCESS: "GET_DETAIL_ROOM_SUCCESS",
  GET_DETAIL_ROOM_FAILED: "GET_DETAIL_ROOM_FAILED",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  GET_MEMBER: "GET_MEMBER",
  GET_MEMBER_SUCCESS: "GET_MEMBER_SUCCESS",
  LEAVE_ROOM: "LEAVE_ROOM",
  LEAVE_ROOM_SUCCESS: "LEAVE_ROOM_SUCCESS",
  LEAVE_ROOM_FAILED: "LEAVE_ROOM_FAILED",
  JOIN_ROOM: "JOIN_ROOM",
  JOIN_ROOM_SUCCESS: "JOIN_ROOM_SUCCESS",
  JOIN_ROOM_FAILED: "JOIN_ROOM_FAILED",
  CLOSE_NOTIFICATION: "CLOSE_NOTIFICATION",
  FIND_MEMBER: "FIND_MEMBER",
  CHANGE_MEMBER_ID: "CHANGE_MEMBER_ID",
  ADD_MEMBER: "ADD_MEMBER",
  ADD_MEMBER_SUCCESS: "ADD_MEMBER_SUCCESS",
  ADD_MEMBER_FAILED: "ADD_MEMBER_FAILED",
  DELETE_NOTIFY: Symbol("DELETE_NOTIFY"),
  GET_LIST_ROOM: "GET_LIST_ROOM",
  GET_LIST_ROOM_SUCCESS: "GET_LIST_ROOM_SUCCESS",
  GET_LIST_ROOM_FAILED: "GET_LIST_ROOM_FAILED",
  CREATE_ROOM: "CREATE_ROOM",
  CREATE_ROOM_SUCCESS: "CREATE_ROOM_SUCCESS",
  CREATE_ROOM_FAILED: "CREATE_ROOM_FAILED",
  GET_CREATION_INFO: "GET_CREATION_INFO",
  GET_CREATION_INFO_SUCCESS: "GET_CREATION_INFO_SUCCESS",
  UPDATE_ROOM: "UPDATE_ROOM",
  UPDATE_ROOM_SUCCESS: "UPDATE_ROOM_SUCCESS",
  UPDATE_ROOM_FAILED: "UPDATE_ROOM_FAILED",
  DELETE_ROOM: "DELETE_ROOM",
  DELETE_ROOM_SUCCESS: "DELETE_ROOM_SUCCESS",
  DELETE_ROOM_FAILED: "DELETE_ROOM_FAILED",
  CLOSE_MODAL: "CLOSE_MODAL",
  DELETE_MEMBER: "DELETE_MEMBER",
  DELETE_MEMBER_SUCCESS: "DELETE_MEMBER_SUCCESS",
  DELETE_MEMBER_FAILED: "DELETE_MEMBER_FAILED",
  CHOOSE_DATE: "CHOOSE_DATE",
  CHOOSE_SUBJECT_ARENA: "CHOOSE_SUBJECT_ARENA",
  GET_GOLD_BOARD: "GET_GOLD_BOARD",
  GET_GOLD_BOARD_SUCCESS: "GET_GOLD_BOARD_SUCCESS",
  GET_GOLD_BOARD_FAILED: Symbol("GET_GOLD_BOARD_FAILED"),
  SORT_ROOM_HISTORY: "SORT_ROOM_HISTORY",
  SORT_TO_SUBJECT: "SORT_TO_SUBJECT",
  GET_HISTORY_ROOM: "GET_HISTORY_ROOM",
  GET_HISTORY_ROOM_SUCCESS: "GET_HISTORY_ROOM_SUCCESS",
  GET_QUIZ_QUESTIONS: "GET_QUIZ_QUESTIONS",
  GET_QUIZ_QUESTIONS_SUCCESS: "GET_QUIZ_QUESTIONS_SUCCESS",
  GET_QUIZ_QUESTIONS_FAILED: "GET_QUIZ_QUESTIONS_FAILED",
  POST_QUIZ_START: "POST_QUIZ_START",
  POST_QUIZ_START_SUCCESS: "POST_QUIZ_START_SUCCESS",
  POST_QUIZ_START_FAILED: "POST_QUIZ_START_FAILED",
  PATCH_QUIZ_START: "PATCH_QUIZ_START",
  PATCH_QUIZ_START_SUCCESS: "PATCH_QUIZ_START_SUCCESS",
  PATCH_QUIZ_START_FAILED: "PATCH_QUIZ_START_FAILED",
  SUBMIT_QUIZ: "SUBMIT_QUIZ",
  SUBMIT_QUIZ_SUCCESS: "SUBMIT_QUIZ_SUCCESS",
  SUBMIT_QUIZ_FAILED: "SUBMIT_QUIZ_FAILED",
  GET_QUIZ_SOLUTIONS: "GET_QUIZ_SOLUTIONS",
  GET_QUIZ_SOLUTIONS_SUCCESS: "GET_QUIZ_SOLUTIONS_SUCCESS",
  GET_QUIZ_SOLUTIONS_FAILED: "GET_QUIZ_SOLUTIONS_FAILED",
  DELETE_QUIZ_INFO_SOLUTIONS: "DELETE_QUIZ_INFO_SOLUTIONS",
  GET_ANALYSIS: "GET_ANALYSIS",
  GET_ANALYSIS_SUCCESS: "GET_ANALYSIS_SUCCESS",
  GET_ANALYSIS_FAILED: "GET_ANALYSIS_FAILED",
  DELETE_QUIZ_INFO_TOKEN: "DELETE_QUIZ_INFO_TOKEN",
  SAVE_ANSWER_TOTAL: "SAVE_ANSWER_TOTAL",
  SHARE_ROOM_TO_ASK: "SHARE_ROOM_TO_ASK",
  UPDATE_QUIZ_SOLUTION_ARENA: "UPDATE_QUIZ_SOLUTION_ARENA",
  OPEN_MODAL_CREATE_POST: Symbol("OPEN_MODAL_CREATE_POST"),
  OPEN_MODAL_CONFIRM_JOIN_ROOM: Symbol("OPEN_MODAL_CONFIRM_JOIN_ROOM"),
  GET_LIST_SUBJECT_ARENA: "GET_LIST_SUBJECT_ARENA",
  GET_LIST_SUBJECT_ARENA_SUCCESS: Symbol("GET_LIST_SUBJECT_ARENA_SUCCESS"),
  GET_LIST_SUBJECT_ARENA_FAILED: Symbol("GET_LIST_SUBJECT_ARENA_FAILED"),
  HANDLE_OPEN_MODAL_GIFT_DIAMOND: Symbol("HANDLE_OPEN_MODAL_GIFT_DIAMOND"),
  GET_HISTORY_ROOM_FAILED: Symbol("GET_HISTORY_ROOM_FAILED"),
  GET_CREATION_INFO_FAILED: Symbol("GET_CREATION_INFO_FAILED"),
  HANDLE_CHOOSE_SUBJECT_FILTER: Symbol("HANDLE_CHOOSE_SUBJECT_FILTER"),
  HANDLE_DELETE_SUCCESS_MESSAGE: Symbol("HANDLE_DELETE_SUCCESS_MESSAGE"),
  SAVE_QUESTION_ID_SCROLL: Symbol("SAVE_QUESTION_ID_SCROLL"),
  CLEAR_QUESTION_ID_SCROLL: Symbol("CLEAR_QUESTION_ID_SCROLL"),
  HANDLE_CHANGE_PAGE: Symbol("HANDLE_CHANGE_PAGE"),
  HANDLE_CHANGE_TAB: Symbol("HANDLE_CHANGE_TAB"),
  CLEAN_DETAIL_ROOM: Symbol("CLEAN_DETAIL_ROOM")
};
