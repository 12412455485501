import React from "react";

const Vietnamese = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1277_42681)">
        <path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill="#D80027"
        />
        <path
          d="M19.9986 10.4355L22.157 17.0784H29.1418L23.4909 21.1839L25.6494 27.8269L19.9986 23.7213L14.3478 27.8269L16.5062 21.1839L10.8555 17.0784H17.8402L19.9986 10.4355Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_1277_42681">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Vietnamese;

export const VietnameseSmall = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1679_107002)">
        <path
          d="M23.25 12C23.25 18.2132 18.2132 23.25 12 23.25C5.7868 23.25 0.75 18.2132 0.75 12C0.75 5.7868 5.7868 0.75 12 0.75C18.2132 0.75 23.25 5.7868 23.25 12Z"
          fill="#D80027"
          stroke="#FCFCFC"
          strokeWidth="1.5"
        />
        <path
          d="M12.0002 6.26123L13.2952 10.247H17.4861L14.0956 12.7103L15.3906 16.696L12.0002 14.2327L8.60969 16.696L9.90475 12.7103L6.51428 10.247H10.7051L12.0002 6.26123Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_1679_107002">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

