import { ROUTES } from "../configs/router";
import { isDebuggerMessage } from "./env";

const has = Object.prototype.hasOwnProperty;

export const isEmpty = (prop: any) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, "length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  );
};

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const ACCESS_TOKEN = "ACCESS_TOKEN";

export const TOKEN = "TOKEN";
export const ID_TOKEN = "ID_TOKEN";
export const PROVIDER_ID = "PROVIDER_ID";

export const LOCALES = "viVN";

export const WIDTH_LAYOUT = 362;
export const WIDTH_LAYOUT_TABLET = 314;

export const LIST_BLOCK_URL = [
  ROUTES.thongke.path,
  ROUTES.taikhoan.path,
  ROUTES.chagrepoint.path,
  ROUTES.taikhoan.diamondhistory.path,
  ROUTES.giftItem.path,
  ROUTES.giftItem.quacuatoi.path
];
export const LIST_NON_BLOCK_URL = [
  ROUTES.home.index,
  ROUTES.subjects.path,
  ROUTES.thionline.path,
  // ROUTES.thionline.xephang.path,
  "thi-online?tab=xep-hang",
  ROUTES.hoidap.path
];

export const LIST_ROUTER_BACK_TO_PREVIOUS_PAGE = ["/thi-online?tab=huy-hieu", "/thi-online?tab=lich-su"];

export const MISSION_VIEW_ADS_EXAM_SKU = "com.jlptup.mission.ViewAdsExamUnlimited";
export const MISSION_VIEW_ADS_SKU = "com.jlptup.mission.ViewAds";
export const MISSION_SHARE_APP = "com.jlptup.mission.ShareApp";
export const MISSION_DAILY_CHECKIN = "com.jlptup.mission.DailyCheckin";
export const MISSION_SETTING_TARGET_POINT = "com.jlptup.mission.SettingTargetPoint";
export const MISSION_UPDATE_INFO = "com.jlptup.mission.UpdateInfo";
export const MISSION_VIEW_ADS_HIDDEN_SKU = [MISSION_VIEW_ADS_EXAM_SKU, "com.jlptup.mission.ViewAdsTopScreenUnlimited"];

export const OPENING_VIEW_TYPE_QUIZ_PDF = "pdf_quiz";
const LIST_ANSWER_MAPPING = [
  {
    id: 1,
    answer: "A"
  },
  {
    id: 2,
    answer: "B"
  },
  {
    id: 3,
    answer: "C"
  },
  {
    id: 4,
    answer: "D"
  },
  {
    id: 5,
    answer: "E"
  },
  {
    id: 6,
    answer: "F"
  }
];
export const answerMapping = (id: number) => {
  return LIST_ANSWER_MAPPING.find((e) => e.id === id)?.answer;
};

export const DebuggerMessage = (message, color?: any) => {
  if (Number(isDebuggerMessage) === 1) {
    if (color) {
      // eslint-disable-next-line no-console
      console.log(`%c${message}`, `color: ${color || "black"}`);
    } else {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  }
};
