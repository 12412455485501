/* eslint-disable no-unused-vars */
/* eslint-disable default-param-last */
import { toast } from "react-toastify";

export const toastSuccess = (mess: string, handleClose?: Function, handleOpen?: Function, closeTime?: number) => {
  return toast.success(mess, { 
    autoClose: closeTime || 1500,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    onOpen: (props) => handleOpen?.(),
    onClose: (props) => handleClose?.()
  });
};

export const toastWarning = (mess: string) => {
  return toast.warn(mess, {
    autoClose: 1500,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light"
  });
};
