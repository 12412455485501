/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useRef } from "react";
import moment from "moment";
import { setCookie } from "cookies-next";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useCookie } from "react-use";
import { genSignature, getNonce, isEmpty } from "../../../utils/helpers/helpers";
import { getConfigServer } from "../../../utils/constants/env";
import { getAuthorize, getUserInfos, signIn } from "../../auth/utils/authAPI";
import { commonActions } from "../redux/actions";
import { TCommonState } from "../utils/type";
import { TParamsSignIn } from "../../Default/layout/type";

type AuthContextInterface = {
  userPubId: any;
  // isEmptyName: boolean;
};

const initAuthContext = {
  userPubId: "",
  // isEmptyName: false,
  congratulation: {},
  successMessage: "",
  errorMessage: undefined
};

const AuthContext = createContext<AuthContextInterface>(initAuthContext);

// custom hook xử lý việc login
export const AuthProvider = ({ children }) => {
  // const [isEmptyName, setIsEmptyName] = useState<boolean>(false);
  const store = useStore();
  const dataFireBaseInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.fireBaseInfos);
  const dataUserInfos = useSelector((state: { commonNew: TCommonState }) => state.commonNew.userInfos);
  const [ServerError, _updateCookie, _deleteCookie] = useCookie("ServerError");
  const userPubId = useRef(dataUserInfos?.member?.pub_id);
  const { accessToken, refreshToken, grade, userLanguage } = useSelector(
    (state: { commonNew: TCommonState }) => state.commonNew
  );
  const dispatch = useDispatch();
  const logout = (token: string) => {
    dispatch(commonActions.handleSaveAccessToken(token));
    dispatch(commonActions.handleSaveIdToken(""));
    dispatch(commonActions.handleSaveProviderId(""));
  };

  const fetchUserInfos = async () => {
    const CONFIG_SERVER: any = await getConfigServer();
    try {
      const params = {
        timestamp: moment().unix(),
        nonce: getNonce(),
        platform: CONFIG_SERVER.platform,
        app_version: process.env.NEXT_PUBLIC_VERSION
      };
      const dataPost = { ...params, signature: genSignature(params) };
      const res = await getUserInfos(store, dataPost);
      if (res?.data) {
        dispatch(commonActions.addUserInfos(res?.data?.data));
        setCookie("userInfo", JSON.stringify(res?.data?.data));
        userPubId.current = res?.data?.data?.member?.pub_id;
        if (!res?.data?.data?.member?.name && res?.data?.data?.member) {
          dispatch(commonActions.handleSetIsEmptyName(res?.data?.data?.member));
        }
      } else logout("");
    } catch (error) {
      dispatch(
        commonActions.handleSaveNotify({
          errorCode: error?.data?.error?.code,
          message: error?.data?.error?.message,
          isOpenNotify: true
        })
      );
    }
  };
  const actionsSignIn = async (paramsSignIn) => {
    const CONFIG_SERVER: any = await getConfigServer();

    const { idTokenParams, providerIdParams, authCode } = paramsSignIn;
    const headers = {
      Authorization: `Bearer ${authCode}`
    };
    let paramsSignatureSignIn: TParamsSignIn = {
      app_version: process.env.NEXT_PUBLIC_VERSION || "",
      ci: CONFIG_SERVER.ci,
      language: CONFIG_SERVER.language,
      timezone: CONFIG_SERVER.timezone,
      timestamp: moment().unix(),
      nonce: getNonce(),
      platform: CONFIG_SERVER.platform,
      os_version: CONFIG_SERVER.os_version
    };

    if (idTokenParams) {
      paramsSignatureSignIn = {
        ...paramsSignatureSignIn,
        id_token: idTokenParams,
        provider_id: providerIdParams
      };
    }

    const params = {
      ...paramsSignatureSignIn,
      signature: genSignature(paramsSignatureSignIn)
    };

    try {
      const response = await signIn(store, params, headers);
      if (response?.data) {
        // setCookie("accessToken", JSON.stringify(response?.data?.data?.token?.access_token));
        dispatch(commonActions.addUserInfos({ ...response?.data?.data, congratulation: {} }));
        if (response?.data?.data?.success_messages[0]) {
          dispatch(
            commonActions.handleSaveNotify({
              message: response?.data?.data?.success_messages[0],
              isOpenNotify: true
            })
          );
        }
        if (idTokenParams) {
          dispatch(commonActions.setActionLoginSuccess());
          fetchUserInfos();
          if (response?.data?.data?.is_new_member) {
            setCookie("is_new_member", "true");
          }
        }
      }
    } catch (err) {}
  };
  const actionsAuthorize = async () => {
    const CONFIG_SERVER: any = await getConfigServer();
    const paramsSignature = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      ci: CONFIG_SERVER.ci,
      platform: CONFIG_SERVER.platform,
      app_version: process.env.NEXT_PUBLIC_VERSION
    };
    const params = {
      ...paramsSignature,
      signature: genSignature(paramsSignature)
    };
    try {
      const response = await getAuthorize(store, params);
      if (response?.data) {
        dispatch(commonActions.handleSaveProviderId(dataFireBaseInfos.providerId));
        dispatch(commonActions.handleSaveIdToken(dataFireBaseInfos.idToken));
        dispatch(commonActions.handleSaveAuthCode(response?.data?.data?.auth_code));
        actionsSignIn({
          idTokenParams: dataFireBaseInfos.idToken,
          providerIdParams: dataFireBaseInfos.providerId,
          authCode: response?.data?.data?.auth_code
        });
      }
    } catch (err) {
      dispatch({
        type: "GET_AUTHORIZE_FAILED",
        params: err?.data?.data?.error
      });
    }
  };
  useEffect(() => {
    if (isEmpty(dataUserInfos?.member) && !isEmpty(dataFireBaseInfos?.idToken)) {
      actionsAuthorize();
    }
  }, [dataFireBaseInfos?.idToken]);

  useEffect(() => {
    setCookie("grade", JSON.stringify(grade));
  }, [grade]);

  useEffect(() => {
    setCookie("userInfo", JSON.stringify(dataUserInfos));
  }, [dataUserInfos]);

  useEffect(() => {
    const serverError = JSON.parse(ServerError || "{}");
    if (!isEmpty(serverError)) {
      store.dispatch(
        commonActions.handleServerError({
          code: serverError?.code,
          message: serverError?.message
        })
      );
      if (serverError?.code === "ENFDVI" || serverError?.code === "ETKINI") {
        // not found device or invalid token
        dispatch(commonActions.handleCleanInvalidToken());
      }
      setCookie("ServerError", "{}");
    } else {
      dispatch(commonActions.deleteNotify());
    }
  }, [ServerError]);
  return <AuthContext.Provider value={{ userPubId }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
