/* eslint-disable import/no-cycle */
import { call, debounce, put, takeEvery } from "redux-saga/effects";
import { store } from "../../../../app/store";
import { toastSuccess } from "../../common/components/reactToastify";
import { homeActions } from "./actions";
import { ACTION_TYPES_HOME } from "./actionTypes";
import { homeSevices } from "./sevices";

function* getGiftCode() {
  try {
    const res = yield call(homeSevices.getListGiftCodes);
    if (res) {
      yield put({ type: ACTION_TYPES_HOME.GET_GIFT_CODE_SUCCESS, params: res.data?.data });
    }
  } catch (err) {}
}
function* takeGiftCode(action) {
  try {
    const res = yield call(homeSevices?.takeGiftCode, action?.params);
    if (res) {
      yield put({
        type: ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE_SUCCESS,
        params: res.data?.data
      });
      toastSuccess(
        res?.data?.data?.success_message,
        () => {},
        () => store.dispatch(homeActions.handleClearNotifyData())
      );
    }
  } catch (err) {
    yield put({
      type: ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE_FAILED,
      params: err?.data?.error
    });
  }
}

function* homeSagas() {
  yield takeEvery(ACTION_TYPES_HOME.GET_GIFT_CODE, getGiftCode);
  yield debounce(300, ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE, takeGiftCode);
}

export default homeSagas;
