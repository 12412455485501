import React from "react";

const IconN5 = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1549_62918)">
        <path
          d="M36 0C16.1159 0 0 16.1141 0 35.9959C0 37.0723 0.0496894 38.1405 0.140787 39.1922C0.836439 47.1747 4.14907 54.4202 9.21739 60.0511C10.9979 62.0384 13.0021 63.827 15.1884 65.3755C19.1967 68.224 23.8261 70.2694 28.8199 71.2796C31.1387 71.7516 33.5404 72 36 72C55.8841 72 72 55.8776 72 35.9959C72 16.1141 55.8841 0 36 0ZM44.4803 11.7585C44.3147 11.8413 44.1242 11.8827 43.9337 11.8827C43.1884 11.8827 42.5838 11.2782 42.5838 10.533C42.5838 9.87878 43.0476 9.33226 43.6687 9.20805C43.7516 9.19149 43.8426 9.18321 43.9337 9.18321C44.6791 9.18321 45.2836 9.78769 45.2836 10.533C45.2836 11.0878 44.9524 11.5597 44.4803 11.7585V11.7585Z"
          fill="#19D2D0"
        />
        <path
          d="M45.4923 6.59961C37.9809 6.59961 32.043 13.0668 32.043 13.0668C32.043 13.0668 32.7718 14.2095 32.7718 15.5675C32.7718 16.9255 31.3142 19.3518 31.2148 19.5174C31.4219 19.4511 34.6931 18.3084 36.0016 15.8822H37.6745C38.8173 15.8822 48.4074 14.6318 52.3743 7.64297C52.3743 7.64297 50.809 6.59961 45.4923 6.59961V6.59961ZM43.9353 11.8826C43.19 11.8826 42.5854 11.2782 42.5854 10.5329C42.5854 9.87874 43.0492 9.33221 43.6703 9.20801C43.7531 9.19144 43.8442 9.18316 43.9353 9.18316C44.6807 9.18316 45.2852 9.78765 45.2852 10.5329C45.2852 11.0877 44.954 11.5597 44.4819 11.7584C44.3163 11.8412 44.1258 11.8826 43.9353 11.8826V11.8826Z"
          fill="white"
        />
        <path
          d="M33.6307 10.2758C33.6307 10.2758 32.5375 9.64648 31.3367 9.64648C30.1359 9.64648 28.082 10.2758 28.082 10.2758C28.082 10.2758 30.6659 11.0873 31.2125 12.23C31.2125 12.23 32.1483 10.872 33.639 10.2758H33.6307Z"
          fill="white"
        />
        <path
          d="M9.73047 48.6151C9.73047 56.283 12.2232 61.6737 15.188 65.3751C19.1963 68.2237 23.8257 70.269 28.8195 71.2792C23.2626 67.644 16.5048 60.7463 16.5048 48.7145C16.5048 28.4436 30.9727 16.2793 31.2046 16.0889C23.5938 19.426 9.73047 29.7436 9.73047 48.6069V48.6151Z"
          fill="#006A69"
        />
        <path
          d="M30.5092 13.8945C30.5092 13.8945 9.3663 18.3329 0.140625 39.1918C0.844559 47.1743 4.14891 54.4199 9.21723 60.0507C11.006 62.0381 13.0019 63.8267 15.1882 65.3752C12.2234 61.6737 9.73069 56.2748 9.73069 48.6152C9.73069 29.7519 23.594 19.426 31.2048 16.0972L30.5092 13.9028V13.8945Z"
          fill="#F8F8F8"
        />
        <path
          d="M32.8376 14.1268C32.7217 13.514 32.5892 12.5866 32.7383 12.3796C32.9453 12.1726 33.7403 11.4439 34.8583 10.5992C34.8915 11.3362 35.0985 12.5535 35.2144 13.5885C35.289 14.1268 35.761 14.3918 36.8625 14.2593C37.9639 14.1185 38.6182 13.6217 38.4857 13.0503C38.1792 11.7006 37.7652 9.72977 37.79 8.72782C39.0488 8.04881 39.8604 7.72586 41.2931 7.2373C41.5664 8.51252 42.055 10.6986 42.4525 12.1891C42.8004 13.5057 40.3821 15.3772 37.144 15.5428C33.9556 15.7084 32.9204 14.6567 32.8293 14.1351L32.8376 14.1268Z"
          fill="#006A69"
        />
        <path
          d="M48.3005 7.22887C46.6111 7.24543 45.5593 7.37792 43.3398 8.04037C43.4392 8.4544 43.5552 8.84359 43.6711 9.20794C43.7539 9.19138 43.845 9.1831 43.9361 9.1831C44.6815 9.1831 45.286 9.78758 45.286 10.5328C45.286 11.0876 44.9547 11.5596 44.4827 11.7584C44.6318 12.2635 44.8802 13.3814 44.963 13.9693C46.404 13.3068 47.1411 12.868 47.9196 12.338C47.9196 11.924 47.8947 11.4106 47.8202 10.9469C47.8202 10.9469 48.4579 10.6405 49.3191 10.2927C50.1804 9.94491 51.2405 8.76907 51.1576 8.1977C51.0666 7.52697 50.081 7.20403 48.2922 7.22887H48.3005ZM48.3916 9.28246C48.0769 9.39839 47.9113 9.46464 47.5634 9.58885C47.4309 9.06717 47.3398 8.79391 47.1576 8.25567C47.5055 8.17286 47.6794 8.13146 48.0107 8.06521C48.9713 7.8582 49.5262 7.96585 49.6421 8.28879C49.7581 8.62001 49.3274 8.94296 48.3916 9.28246V9.28246Z"
          fill="#006A69"
        />
        <path
          d="M43.9358 12.0901C43.0746 12.0901 42.3789 11.3945 42.3789 10.5333C42.3789 9.79634 42.9089 9.15046 43.6294 9.00968C43.7288 8.99312 43.8282 8.97656 43.9358 8.97656C44.7971 8.97656 45.4928 9.67213 45.4928 10.5333C45.4928 11.1544 45.1284 11.7092 44.5652 11.9493C44.383 12.0404 44.1677 12.0901 43.9358 12.0901Z"
          fill="#02BCBC"
        />
        <path
          d="M43.9359 9.18356C44.6812 9.18356 45.2857 9.78805 45.2857 10.5333C45.2857 11.0881 44.9545 11.5601 44.4824 11.7588C44.3168 11.8416 44.1263 11.883 43.9359 11.883C43.1905 11.883 42.586 11.2786 42.586 10.5333C42.586 9.87913 43.0497 9.33261 43.6708 9.2084C43.7537 9.19184 43.8448 9.18356 43.9359 9.18356V9.18356ZM43.9359 8.76953C43.8199 8.76953 43.6957 8.77781 43.588 8.80265C42.7682 8.96827 42.1719 9.69696 42.1719 10.5333C42.1719 11.5021 42.9669 12.2971 43.9359 12.2971C44.1926 12.2971 44.4493 12.2391 44.6646 12.1315C45.2857 11.8748 45.6998 11.2454 45.6998 10.5333C45.6998 9.56447 44.9048 8.76953 43.9359 8.76953Z"
          fill="white"
        />
        <path
          d="M43.4883 30.9375V48H40.2539L33 36.1641V48H29.7773V30.9375H33L40.2773 42.7852V30.9375H43.4883ZM49.4414 40.1484L46.9688 39.5508L47.918 30.9375H57.3047V33.5039H50.4844L50.0391 37.4883C50.2812 37.3398 50.6328 37.1914 51.0938 37.043C51.5625 36.8867 52.0898 36.8086 52.6758 36.8086C53.4883 36.8086 54.2148 36.9414 54.8555 37.207C55.4961 37.4648 56.0391 37.8398 56.4844 38.332C56.9375 38.8242 57.2773 39.4258 57.5039 40.1367C57.7383 40.8398 57.8555 41.6367 57.8555 42.5273C57.8555 43.3086 57.7383 44.043 57.5039 44.7305C57.2773 45.4102 56.9297 46.0156 56.4609 46.5469C55.9922 47.0703 55.4023 47.4844 54.6914 47.7891C53.9805 48.0859 53.1445 48.2344 52.1836 48.2344C51.457 48.2344 50.7578 48.1289 50.0859 47.918C49.4219 47.6992 48.8242 47.3828 48.293 46.9688C47.7617 46.5469 47.3359 46.0312 47.0156 45.4219C46.7031 44.8125 46.5312 44.1133 46.5 43.3242H49.5586C49.6133 43.8477 49.7539 44.293 49.9805 44.6602C50.207 45.0273 50.5039 45.3086 50.8711 45.5039C51.2461 45.6992 51.6797 45.7969 52.1719 45.7969C52.6172 45.7969 53 45.7148 53.3203 45.5508C53.6484 45.3789 53.918 45.1406 54.1289 44.8359C54.3398 44.5312 54.4961 44.1719 54.5977 43.7578C54.6992 43.3438 54.75 42.8906 54.75 42.3984C54.75 41.9219 54.6914 41.4883 54.5742 41.0977C54.457 40.6992 54.2773 40.3555 54.0352 40.0664C53.793 39.7773 53.4883 39.5547 53.1211 39.3984C52.7617 39.2422 52.3398 39.1641 51.8555 39.1641C51.207 39.1641 50.707 39.2617 50.3555 39.457C50.0117 39.6523 49.707 39.8828 49.4414 40.1484Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1549_62918">
          <rect width="72" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconN5;
