import { combineReducers } from "redux";
import searchReducer, { initialStateSearch } from "../components/features/search/redux/reducer";
import homeReducer, { initialStateHome } from "../components/features/Homepage/redux/reducer";
import giftItemReducer, { initialStateGiftItem } from "../components/features/giftItem/redux/reducers";
import friendReducer, { initialStateFriend } from "../components/features/friends/redux/reducers";
import newsReducer, { initialStateNews } from "../components/features/news/redux/reducer";
import subjectReducer, { initialStateSubject } from "../components/features/subjects/redux/reducers";
import questionAndAnswerReducer, { initialStateQA } from "../components/features/questionAndAnswer/redux/reducer";
import arenaReducer, { initialStateArena } from "../components/features/arena/redux/reducer";
import changePointReducer, { initialStateChargePoint } from "../components/features/Chagrepoint/redux/reducers";
import quizReducer, { initialStateQuiz } from "../components/features/topics/redux/reducer";
import accountReducer, { initialStateAccount } from "../components/features/account/redux/reducers";
import commonReducer, { initialStateCommon } from "../components/features/common/redux/reducers";
import systemSlice, { initialState as initialStateSystem } from "../components/features/system/systemSlice";

const combineReducer = combineReducers({
  system: systemSlice,
  arenaRoom: arenaReducer,
  commonNew: commonReducer,
  account: accountReducer,
  changePoint: changePointReducer,
  quizCore: quizReducer,
  subject: subjectReducer,
  questionAndAnswer: questionAndAnswerReducer,
  newsState: newsReducer,
  friend: friendReducer,
  giftItem: giftItemReducer,
  home: homeReducer,
  search: searchReducer,
});
export const rootReducers = (state, action) => {
  return combineReducer(state, action);
};
export const initialState = {
  system: initialStateSystem,
  arenaRoom: initialStateArena,
  commonNew: initialStateCommon,
  account: initialStateAccount,
  changePoint: initialStateChargePoint,
  quizCore: initialStateQuiz,
  subject: initialStateSubject,
  questionAndAnswer: initialStateQA,
  newsState: initialStateNews,
  friend: initialStateFriend,
  giftItem: initialStateGiftItem,
  home: initialStateHome,
  search: initialStateSearch
};
