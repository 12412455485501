/* eslint-disable react/require-default-props */
import React from "react";

// eslint-disable-next-line no-unused-vars
type IconChatsCircle = {
  className?: string;
  fill?: string;
  width?: string;
  height?: string;
};

export default function IconChatsCircle({
  className,
  fill,
  width,
  height
}: IconChatsCircle) {
  return (
    <svg
      className={className}
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill={fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 9C17 12.87 13.64 16 9.5 16L8.57001 17.12L8.02 17.78C7.55 18.34 6.65 18.22 6.34 17.55L5 14.6C3.18 13.32 2 11.29 2 9C2 5.13 5.36 2 9.5 2C12.52 2 15.13 3.67001 16.3 6.07001C16.75 6.96001 17 7.95 17 9Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0003 12.8603C22.0003 15.1503 20.8203 17.1803 19.0003 18.4603L17.6603 21.4103C17.3503 22.0803 16.4503 22.2103 15.9803 21.6403L14.5003 19.8603C12.0803 19.8603 9.92031 18.7903 8.57031 17.1203L9.50031 16.0003C13.6403 16.0003 17.0003 12.8703 17.0003 9.00031C17.0003 7.95031 16.7503 6.96031 16.3003 6.07031C19.5703 6.82031 22.0003 9.58029 22.0003 12.8603Z"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 9H12"
        stroke="#E5E5E5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
