import { HYDRATE } from "next-redux-wrapper";
import { TGiftItemState } from "../utils/types";
import { ACTION_TYPES } from "./actionTypes";

export const initialStateGiftItem: TGiftItemState = {
  listGiftItem: {
    items: [],
    categories: []
  },
  listGiftItemHistory: {
    items: []
  },
  isLoading: false,
  giftItemDetail: {
    gift_item_id: 0,
    title: "",
    images: [
      {
        ordinal: 0,
        image_url: ""
      }
    ],
    counter: 0,
    expiry: 0,
    description: "",
    available: false,
    type: "",
    member_max_times: 0,
    activate_times: 0,
    conditions: {
      level: {
        available: false,
        level: 0
      },
      grade: {
        available: false,
        title: []
      },
      membership_type: {
        available: false,
        type: ""
      },
      num_of_vote: {
        available: false,
        quantity: 0
      },
      num_of_accept: {
        available: false,
        quantity: 0
      },
      achievements: [
        {
          available: false,
          id: 0,
          title: "",
          description: ""
        }
      ],
      arena_rooms: [
        {
          available: false,
          id: 0,
          title: "",
          top: 0,
          link_app: "",
          link_web: ""
        }
      ],
      limit_exchange: {
        available: false,
        type: ""
      }
    },
    fee: {
      diamond: {
        available: false,
        diamond: 0
      },
      star: {
        available: false,
        star: 0
      },
      discounts: {
        percent: 0,
        description: "",
        valid_from: 0,
        valid_to: 0
      }
    }
  },
  giftItemExchange: {
    gift_code: "",
    valid_to: 0,
    success_message: ""
  },
  notify: {
    notify: "",
    isOpenDialog: false,
    isOpenNotify: false,
    message: "",
    errorCode: "",
    description: "",
    type: "",
    congratulation: {
      success_message: "",
      congratulation_message: "",
      reward_amount: 0,
      reward_type: ""
    }
  }
};

// eslint-disable-next-line default-param-last
const giftItemReducer = (state = initialStateGiftItem, action: any) => {
  switch (action?.type) {
    case ACTION_TYPES.GET_GIFT_ITEM_SUCCESS: {
      return {
        ...state,
        listGiftItem: action.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_GIFT_ITEM: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_GIFT_ITEM_FAILED: {
      return {
        ...state,
        listGiftItem: action.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_GIFT_ITEM_DETAIL_SUCCESS: {
      return {
        ...state,
        giftItemDetail: action?.params || null,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_GIFT_ITEM_DETAIL: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_GIFT_ITEM_DETAIL_FAILED: {
      return {
        ...state,
        giftItemDetail: action.params,
        isLoading: false
      };
    }

    case ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE_SUCCESS: {
      return {
        ...state,
        giftItemExchange: action.params,
        isLoading: false,
        notify: {
          type: "GET_GIFT_ITEM_EXCHANGE_SUCCESS",
          message: action?.params?.success_message,
          errorCode: "",
          isOpenDialog: false,
          isOpenNotify: true
        }
      };
    }

    case ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE: {
      return {
        ...state,
        isLoading: false
      };
    }

    case ACTION_TYPES.GET_GIFT_ITEM_EXCHANGE_FAILED: {
      return {
        ...state,
        giftItemExchange: action.params,
        isLoading: false,
        notify: {
          type: "GET_GIFT_ITEM_DETAIL_EXCHANGE_FAILED",
          message: action?.params?.message,
          errorCode: action?.params?.code,
          isOpenDialog: true,
          isOpenNotify: false
        }
      };
    }

    case ACTION_TYPES.GET_GIFT_ITEM_HISTORY_SUCCESS: {
      return {
        ...state,
        listGiftItemHistory: action.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.GET_GIFT_ITEM_HISTORY: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTION_TYPES.GET_GIFT_ITEM_HISTORY_FAILED: {
      return {
        ...state,
        listGiftItemHistory: action.params,
        isLoading: false
      };
    }
    case ACTION_TYPES.CLEAR_NOTIFY: {
      return {
        ...state,
        notify: {
          isOpenNotify: false,
          isOpenDialog: false,
          message: "",
          type: ""
        }
      };
    }
    case ACTION_TYPES.CLEAN_GIFT_ITEM_DETAIL: {
      return {
        ...state,
        giftItemDetail: {}
      };
    }
    case ACTION_TYPES.CLEAN_GIFT_ITEM_EXCHANGE: {
      return {
        ...state,
        giftItemExchange: {}
      };
    }
    case HYDRATE: {
      return {
        ...state,
        listGiftItem: action?.payload?.giftItem?.listGiftItem || null,
        giftItemDetail: action?.payload?.giftItem?.giftItemDetail || null,
        listGiftItemHistory: action?.payload?.giftItem?.listGiftItemHistory || null
      };
    }
    default:
      return state;
  }
};
export default giftItemReducer;
