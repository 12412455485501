export const ACTION_TYPES = {
  GET_LIST_SUBJECTS: "GET_LIST_SUBJECTS",
  GET_LIST_SUBJECTS_SUCCESS: "GET_LIST_SUBJECTS_SUCCESS",
  GET_LIST_SUBJECTS_FAILED: "GET_LIST_SUBJECTS_FAILED",
  GET_LIST_TOPIC: "GET_LIST_TOPIC",
  GET_LIST_TOPIC_SUCCESS: "GET_LIST_TOPIC_SUCCESS",
  GET_LIST_TOPIC_FAILED: "GET_LIST_TOPIC_FAILED",
  CLEAN_NOTIFY: "CLEAN_NOTIFY",
  HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND: Symbol("HANDLE_CHANGE_OPEN_MODAL_EARN_DIAMOND"),
  HANDLE_CHOOSE_PAGE: Symbol("HANDLE_CHOOSE_PAGE"),
  HANDLE_CHANGE_TAB: Symbol("HANDLE_CHANGE_TAB"),
  GET_LIST_CARD: "GET_LIST_CARD",
  GET_LIST_CARD_SUCCESS: "GET_LIST_CARD_SUCCESS",
  GET_LIST_CARD_FAILED: "GET_LIST_CARD_FAILED"
};
