/* eslint-disable */
import axios from "axios";
import { getTokens } from "../../features/auth/utils/authAPI";
import moment from "moment";
import Qs from "qs";
import { DebuggerMessage } from "./../constants/constants";
import { API_VERSION, getConfigServer } from "../constants/env";
import { getNonce, genSignature } from "./helpers";
import { isEmpty } from "../constants/constants";
import Router from "next/router";
import { getCookie, setCookie } from "cookies-next";
declare const window: any;

const API_STATUS_ERROR = [500, 501, 502];
const request = axios.create({ withCredentials: true });
axios.defaults.timeout = 20000;
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
let isAlreadyFetchingAccessToken = false;
let subscribers: any = [];

function onAccessTokenFetched(access_token: string) {
  subscribers = subscribers.map((callback: any) => callback(access_token));
  subscribers = [];
}

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error),
  { synchronous: true }
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const res = (error && error.response) || {};
    const accessToken = JSON.parse((getCookie("accessToken") as string) || "{}");
    if (res.status === 401) {
      if (res?.data?.error?.code === "ETKEXI") {
        if (accessToken && error.config.url.indexOf("/token") === -1) {
          originalRequest._retry = true;
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            const CONFIG_SERVER: any = await getConfigServer();
            const dataDTOPre = {
              timestamp: moment().unix(),
              nonce: getNonce(),
              ci: CONFIG_SERVER.ci,
              platform: CONFIG_SERVER.platform,
              app_version: process.env.NEXT_PUBLIC_VERSION
            };
            const dataDTO = {
              ...dataDTOPre,
              signature: genSignature(dataDTOPre)
            };
            const headerDTO: any = {};
            headerDTO["Refresh-Token"] = JSON.parse((getCookie("refreshToken") as string) || "{}");
            getTokens(dataDTO, headerDTO)
              ?.then(({ data }) => {
                if (data) {
                  const userInfo = JSON.parse((getCookie("userInfo") as string) || "{}");
                  setCookie("accessToken", JSON.stringify(data?.data?.access_token));
                  setCookie("refreshToken", JSON.stringify(data?.data?.ref_token));
                  setCookie("userInfo", JSON.stringify({ ...userInfo, token: data?.data }));

                  onAccessTokenFetched(data?.data?.access_token);
                  // Router.reload();
                }
              })
              .catch((error) => {
                isAlreadyFetchingAccessToken = false;
                subscribers = [];
              });
            // const retryOriginalRequest = new Promise((resolve) => {
            //   addSubscriber((access_token: any) => {
            //     originalRequest.headers.Authorization = "Bearer " + access_token;
            //     resolve(axios(originalRequest));
            //   });
            // });
            // return retryOriginalRequest;
          }
        } else {
          subscribers = [];
        }
      } else if (res?.data?.error?.code === "ETKINI") {
        // setCookie("ServerError", JSON.stringify(res?.data?.error));
        setCookie("accessToken", "");
        setCookie("userInfo", "{}");
        // clear local Storage
        // typeof window !== "undefined" && localStorage.clear();
      } else {
        setCookie("ServerError", JSON.stringify(res?.data?.error));
      }
    } else if (API_STATUS_ERROR.includes(res.status)) {
      if (typeof window !== "undefined") {
        if (error.config?.url === "list-gift-codes") {
          // pass
        } else if (error?.config?.url !== "news" || error?.config?.url !== "posts") {
          setCookie("ServerError", JSON.stringify(res?.data?.error));
        } else {
          Router.push("/500");
        }
      }
    } else if (res.status === 404 && res?.data?.error?.code === "ENFDVI") {
      setCookie("ServerError", JSON.stringify(res?.data?.error));
      setCookie("accessToken", "");
      setCookie("userInfo", "{}");
    }
    return Promise.reject(error.response || { data: {} });
  }
);

const getBaseUrl = () => {
  if (isEmpty(API_VERSION)) {
    return process.env.NEXT_PUBLIC_BASE_URL_API;
  }
  return `${process.env.NEXT_PUBLIC_BASE_URL_API}${API_VERSION}/`;
};

const api = (options: any, arrayFormat?: "comma" | "indices" | "brackets" | "repeat" | undefined, store?: any) => {
  let accessToken: string = "";
  if (store) {
    accessToken =
      store?.getState()?.commonNew?.userInfos?.token?.access_token || store?.getState()?.commonNew?.accessToken;
  } else {
    accessToken =
      JSON.parse((getCookie("userInfo") as string) || "{}")?.token?.access_token ||
      JSON.parse((getCookie("accessToken") as string) || "{}");
  }

  DebuggerMessage(`Token: ${JSON.stringify(accessToken)}`);
  if (accessToken && !options?.noAuthentication) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`
    };
  }
  options.headers = {
    ...options.headers,
    ["Client-Key"]: process.env.NEXT_PUBLIC_APPLICATION_CLIENT_KEY,
    "Accept-Language": "vi"
  };
  return request({
    baseURL: getBaseUrl(),
    ...options,
    paramsSerializer: (params) => Qs.stringify(params, { arrayFormat: arrayFormat || "comma" })
  });
};

export default api;
