import api from "../../../utils/helpers/api";

export const getTopics = (params) => {
  const option = {
    method: "get",
    url: `topics/${params.subjectId}`,
    params
  };
  return api(option);
};

export const getSubjects = (subjectId) => {
  const option = {
    method: "get",
    url: `subjects/${subjectId}`,
  };
  return api(option);
};
export const subjectServices = {
  getSubjects,
  getTopics,
};
